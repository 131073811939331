<template>
  <f7-page noNavbar name="st-elevation-no">
    
    <f7-block>
      <h3>Consider:</h3>
    </f7-block>

    <f7-list media-list class="mt-0 mb-0">
      <html-list-item
        v-for="item in questions"
        checkbox
        :name="item.name"
        :key="item.id"
        :value="item.id"
        :checked="getAnswer(item.name)===item.id"
        @change="setAnswerRadio"
        :title="item.title"
        :subtitle="item.subtitle"
        :text="item.text"
      ></html-list-item>
    </f7-list>

    <f7-block class="text-center" :class="{disabled: getAnswer('ste_radio')!=='adpe7'}">
      <h3 style="font-weight:normal;" class="mt-0 mb-1"><em>Diagnose</em></h3>
      <h3 class="mt-0">Non ST Elevation Acute Coronary Syndrome (ACS)</h3>
      <f7-button fill href="/stratify-acs-risk-high/">STRATIFY ACS RISK</f7-button>
    </f7-block>

  </f7-page>
</template>

<script>
  import HtmlListItem from './html-list-item.vue';
  import helpers from './helpers.vue';
  export default {
    extends: helpers,
    mounted() {
      document.title = 'Non ST Elevation - Blacktown Hospital PACSA'
    },
    data() {
      return {
        section: 'ste_answers',
        questions: [
          { 
            id: 'adpe1', name: 'ste_radio', title: 'Aortic Dissection', 
            subtitle: '(back pain, hypertension, absent pulse, BP difference)' 
          },
          { 
            id: 'adpe2', name: 'ste_radio', title: 'Pulmonary Embolism', 
            subtitle: '(severe dyspnoea, respiratory distress, low O<sub>2</sub> saturation)', 
          },
          { 
            id: 'adpe3', name: 'ste_radio', title: 'Pericarditis'
          },
          { 
            id: 'adpe4', name: 'ste_radio', title: 'Trauma'
          },
          { 
            id: 'adpe5', name: 'ste_radio', title: 'Gastrointestinal'
          },
          { 
            id: 'adpe6', name: 'ste_radio', title: 'Musculoskeletal'
          },
          { 
            id: 'adpe7', name: 'ste_radio', title: 'None of the above'
          },
        ],
      }
    },
    components: {
      HtmlListItem,
    },
  }
</script>