import { render, staticRenderFns } from "./alternative-causes-of-troponin-elevation.vue?vue&type=template&id=1cb0553f&"
import script from "./alternative-causes-of-troponin-elevation.vue?vue&type=script&lang=js&"
export * from "./alternative-causes-of-troponin-elevation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports