<template>
<f7-app :params="f7params" >
  <!-- Status bar overlay for fullscreen mode-->
  <f7-statusbar></f7-statusbar>

  <!-- Right panel with reveal effect-->
  <f7-panel right cover>
    <f7-page>
      <f7-navbar class="panel-nav-title" title="Main Navigation"></f7-navbar>
      <f7-list links-list class="nav-content">
        <f7-list-button link="#" @click="returnHome()" class="item-link" title="Home" panel-close></f7-list-button>
        <f7-list-button link="/stratify-acs-risk-high/" class="item-link" title="Stratify Risk" panel-close></f7-list-button>
        <f7-list-button link="/management-risk-high/" class="item-link risk-link red-link" title="High Risk" panel-close></f7-list-button>
        <f7-list-button link="/management-risk-intermediate/" class="item-link risk-link yellow-link" title="Intermediate Risk" panel-close></f7-list-button>
        <f7-list-button link="/management-risk-low/" class="item-link risk-link green-link" title="Low Risk" panel-close></f7-list-button>
        <f7-list-button link="/stemi-management/" class="item-link" title="STEMI Management" panel-close></f7-list-button>
        <f7-list-button link="/troponin/" class="item-link" title="High sensitivity Troponin I" panel-close></f7-list-button>
        <f7-list-button link="/hs-trop-calculator/" class="item-link" title="HS Trop I calculator BTMD Siemens Vista HS assay" panel-close></f7-list-button>
        <f7-list-button link="/anticoagulation/" class="item-link" title="Anticoagulation" panel-close></f7-list-button>
        <f7-list-button link="/edacs/" class="item-link" title="EDACS" panel-close></f7-list-button>
        <f7-list-button link="/testing-guideline/" class="item-link" title="Non Invasive Testing Guideline" panel-close></f7-list-button>
        <f7-list-button link="/references/" class="item-link" title="References" panel-close></f7-list-button>
      </f7-list>
    </f7-page>
  </f7-panel>

  <f7-appbar>
    <div class="left">
      <a class="link back" id="appbar-back">
        <i class="icon icon-back"></i>
        <span class="if-not-md">Back</span>
      </a>
    </div>
    <div class="center">
      <img src="static/images/logo.jpg" class="logo" border="0" style="max-height: 40px;display:block;">
    </div>
    <div class="right">
      <a href="#" class="button button-small panel-toggle display-flex" data-panel="right">
        <i class="f7-icons">bars</i>
      </a>
    </div>
  </f7-appbar>
  
  <!-- Your main view, should have "view-main" class -->
  <f7-view main :push-state="true" class="safe-areas" url="/"></f7-view>

</f7-app>
</template>
<script>

  import routes from '../js/routes.js';

  export default {
    data() {
      return {
        // Framework7 Parameters
        f7params: {
          name: 'Blacktown Hospital - Chest Pain Pathway', // App name
          theme: 'ios', // Automatic theme detection
          // App root data
          data: function () {

          },
          on: {
            routeChange: function (page) {
              if (page.path == '/') {
                document.getElementById("appbar-back").classList.remove("active");
              } else {
                document.getElementById("appbar-back").classList.add("active");
              }
            },
          },
          // App routes
          routes: routes,

          // Register service worker
          serviceWorker: {
            path: '/service-worker.js',
          },
        },
      }
    },
    methods: {
      returnHome: function() {
        this.$f7.views.main.router.back('/', { force:true });
      },
    },
    mounted() {
      this.$f7ready((f7) => {

        // Call F7 APIs here
      });
    }
  }
</script>