<template>
  <f7-page noNavbar name="references">

    <f7-block class="text-center">
      <p class="mb-0 med-page-title"><strong>References</strong></p>
    </f7-block>

    <f7-list media-list class="mt-0 mb-0">
      <html-list-item
        v-for="item in questions"
        :checkbox="!(item.isDivider||item.isSecondary)"
        :divider="item.isDivider"
        :name="item.name"
        :key="item.id"
        :value="item.id"
        
        :checked="getAnswer(item.id)===true"
        @change="setAnswerCheckbox"
        :title="item.title"
        :subtitle="item.subtitle"
        :text="item.text"
        :class="item.class"
      ></html-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
  import HtmlListItem from './html-list-item.vue';
  import helpers from './helpers.vue';
  export default {
    extends: helpers,
    mounted() {
      document.title = 'References - Blacktown Hospital PACSA'
    },
    data() {
      return {
        section: 'references',
        questions: [
            { id: 'ref-01', name: 'ref_block', title: '<p class="para mb-0"><a href="https://www1.health.nsw.gov.au/pds/Pages/doc.aspx?dn=GL2019_014" class="external">Pathway for Acute Coronary Syndrome Assessment (PACSA)</a></p>', isSecondary: true },
            { id: 'ref-02', name: 'ref_block', title: '<p class="para mb-0"><a href="http://virtapp-mas109.nswhealth.net/WSLHD_DOCV3/search/MainSearch.aspx" class="external">Anticoagulation guidelines Adult WSLHD</a></p>', isSecondary: true },
            { id: 'ref-03', name: 'ref_block', title: '<p class="para">ESC Clinical Practice Guidelines:</p><p class="para"><a href="https://www.escardio.org/Guidelines/Clinical-Practice-Guidelines/Acute-Myocardial-Infarction-in-patients-presenting-with-ST-segment-elevation-Ma" class="external">ST elevation Published 2017</a></p><p class="para mb-0"><a href="https://www.escardio.org/Guidelines/Clinical-Practice-Guidelines/Acute-Coronary-Syndromes-ACS-in-patients-presenting-without-persistent-ST-segm" class="external">Non ST elevation</a></p>', isSecondary: true },
            { id: 'ref-04', name: 'ref_block', title: '<p class="para mb-0"><a href="https://www.heartfoundation.org.au/for-professionals/clinical-information/acute-coronary-syndromes" class="external">NHFA/CSANZ ACS Guidelines 2016</a></p>', isSecondary: true },
        ],
      }
    },
    computed: {

    },
    components: {
      HtmlListItem,
    },
  }
</script>