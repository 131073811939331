<template>
  <f7-page noNavbar name="testing-guideline">

    <f7-block class="text-center">
      <p class="mb-0 med-page-title"><strong>Non-invasive testing guideline</strong></p>
    </f7-block>

    <f7-list media-list class="mt-0 mb-0">
      <html-list-item
        v-for="item in questions"
        :checkbox="!(item.isDivider||item.isSecondary)"
        :divider="item.isDivider"
        :name="item.name"
        :key="item.id"
        :value="item.id"
        
        :checked="getAnswer(item.id)===true"
        @change="setAnswerCheckbox"
        :title="item.useEdacsTitle === true ? edacsTitle : item.title"
        :subtitle="item.subtitle"
        :text="item.text"
        :class="item.class"
      ></html-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
  import HtmlListItem from './html-list-item.vue';
  import helpers from './helpers.vue';
  export default {
    extends: helpers,
    mounted() {
      document.title = 'Non-invasive testing guideline - Blacktown Hospital PACSA'
    },
    data() {
      return {
        section: 'testing-guideline',
        questions: [
          // { id: 'guide-01', name: 'nitg_check', title: 'Non invasive testing guideline', isSecondary: true },
          { id: 'guide-01', name: 'nitg_check', title: '<p class="para">If low probability (EDACS &lt;16) and no contraindication for exercise stress test then EST is first line test.</p><p class="para">If higher probability (EDACS ≥16) and no contraindication, CTCA first line.</p><p class="para mb-0">If neither suitable discuss with cardiology.</p>', isSecondary: true },
          { id: 'guide-02', name: 'nitg_check', title: '', isSecondary: true, class: 'edacs', useEdacsTitle: true },
          // { id: 'guide-03', name: 'nitg_check', title: '', isSecondary: true },
          // { id: 'guide-04', name: 'nitg_check', title: '', isSecondary: true },
          { id: 'guide-05', name: 'nitg_check', title: '<strong>Stress test checklist</strong>', isSecondary: true, class: 'bg-grey' },
          { id: 'guide-06', name: 'nitg_check', title: '1. Can walk &gt;500m unassisted' },
          { id: 'guide-07', name: 'nitg_check', title: '2. No ongoing chest pain' },
          { id: 'guide-08', name: 'nitg_check', title: '3. 2x HS troponin normal' },
          { id: 'guide-09', name: 'nitg_check', title: '4. No evidence of significant valvular heart disease on physical examination aortic stenosis or regurgitation' },
          { id: 'guide-10', name: 'nitg_check', title: '5. ECG – no LBBB or severe LVH' },
          { id: 'guide-11', name: 'nitg_check', title: '6. Safe for beta blocker or verapamil/ diltiazem to be withheld 24hrs prior to test' },
          { id: 'guide-12', name: 'nitg_check', title: '<strong>Outpatient exercise stress test</strong>', isSecondary: true, class: 'bg-grey' },
          { id: 'guide-13', name: 'nitg_check', title: '<p class="para">If yes to all</p><ol class="testing-list"><li>Book EST online in powerorders</li><li>In clinical indication include:<ol type="a"><li><strong>OUTPATIENT</strong> as first word</li><li>Patient’s best contact <strong>phone number</strong></li><li>Followed by history</li></ol></li><li>Indicate if low risk can be done within 2 weeks or intermediate within 1 week.</li><li>Cardiology staff will phone patient on the next working day to give appointment time. Give patient clinical measurements phone number - 8670 8295 to check on booking in case they do not hear within 1 working day.</li></ol>', isSecondary: true },
          //{ id: 'guide-14', name: 'nitg_check', title: '1. Book EST online in powerorders', isSecondary: true },
          //{ id: 'guide-15', name: 'nitg_check', title: '2. In clinical indication include:', isSecondary: true },
          //{ id: 'guide-16', name: 'nitg_check', title: 'a. <strong>OUTPATIENT</strong> as first word', isSecondary: true },
          //{ id: 'guide-17', name: 'nitg_check', title: 'b. Patient’s best contact <strong>phone number</strong>', isSecondary: true },
          //{ id: 'guide-18', name: 'nitg_check', title: 'c. Followed by history', isSecondary: true },
          //{ id: 'guide-19', name: 'nitg_check', title: '3. Indicate if low risk can be done within 2 weeks or intermediate within 1 week.', isSecondary: true },
          //{ id: 'guide-20', name: 'nitg_check', title: '4. Cardiology staff will phone patient on the next working day to give appointment time. Give patient clinical measurements phone number - 9881 8295 to check on booking in case they do not hear within 1 working day.', isSecondary: true },
          { id: 'guide-21', name: 'nitg_check', title: '<strong>CT coronary angiogram (CTCA) checklist</strong>', isSecondary: true, class: 'bg-grey' },
          { id: 'guide-22', name: 'nitg_check', title: '1. No iodine allergy' },
          { id: 'guide-23', name: 'nitg_check', title: '2. Normal renal function' },
          { id: 'guide-24', name: 'nitg_check', title: '3. In sinus rhythm' },
          { id: 'guide-25', name: 'nitg_check', title: '4. Can take rate slowing medication to achieve HR of ≤60 BPM,' },
          { id: 'guide-26', name: 'nitg_check', title: '<strong>Outpatient CTCA</strong> If yes to all', isSecondary: true, class: 'bg-grey' },
          { id: 'guide-27', name: 'nitg_check', title: '1. Discuss with cardiologist (Medicare requirement for outpatient referral)' },
          { id: 'guide-28', name: 'nitg_check', title: '2. Prescribe rate slowing medication, consider contraindications and BP then <strong>give script</strong> for one of;' },
          { id: 'guide-29', name: 'nitg_check', title: '<ol class="testing-list" type="a" style="margin-left:56px;"><li>Metoprolol 50mg night before and morning of CT</li><li>Verapamil 80mg night before and morning of CT</li><li>Ivabradine 7.5mg night before and morning of CT</li><ol>', isSecondary: true },
          //{ id: 'guide-30', name: 'nitg_check', title: 'b. Verapamil 80mg night before and morning of CT', isSecondary: true },
          //{ id: 'guide-31', name: 'nitg_check', title: 'c. Ivabradine 7.5mg night before and morning of CT', isSecondary: true },
          { id: 'guide-32', name: 'nitg_check', title: '3. Provide outpatient referral form to patient for private CTCA with early follow up with cardiologist the patient has been discussed with in point 1. Serum creatinine listed on form.' },
          // { id: 'guide-33', name: 'nitg_check', title: '<ol class="testing-list" type="a" style="margin-left:56px;"><li>Note patient is OUTPATIENT on form</li><li>Ensure that patient’s best contact <strong>phone number</strong> is on form</li><li>Cardiologist discussed with listed</li><li></li><li>Provide patient with Radiology Number 9881 8229. Radiology staff will phone patient on the next working day to give appointment time.</li><ol>', isSecondary: true },
          //{ id: 'guide-34', name: 'nitg_check', title: 'b. Ensure that patient’s best contact <strong>phone number</strong> is on form', isSecondary: true },
          //{ id: 'guide-35', name: 'nitg_check', title: 'c. Cardiologist discussed with listed', isSecondary: true },
          //{ id: 'guide-36', name: 'nitg_check', title: 'd. Serum creatinine listed', isSecondary: true },
          //{ id: 'guide-37', name: 'nitg_check', title: 'e. Provide patient with Radiology Number 9881 8229. Radiology staff will phone patient on the next working day to give appointment time.', isSecondary: true },
        ],
      }
    },
    computed: {
      edacsTitle() {
        return `Current <a href="/edacs/">EDACS</a> Score: <strong>${this.$store.getters.getEdacsScore}</strong>`;
      },
    },
    components: {
      HtmlListItem,
    },
  }
</script>