<template>
  <f7-page noNavbar name="anticoagulation">

    <f7-block class="text-center">
      <p class="mb-0 med-page-title"><strong>Anticoagulation</strong></p>
    </f7-block>

    <f7-list media-list class="mt-0 mb-0">
        <html-list-item
            v-for="item in questions"
            :checkbox="!(item.isDivider||item.isSecondary)"
            :divider="item.isDivider"
            :name="item.name"
            :key="item.id"
            :value="item.id"
            
            :checked="getAnswer(item.id)===true"
            @change="setAnswerCheckbox"
            :title="item.title"
            :subtitle="item.subtitle"
            :text="item.text"
            :class="item.class"
        ></html-list-item>

    </f7-list>

    <div class="data-table card">
    <table>
        <thead>
        <tr>
            <th class="label-cell bg-grey text-black">Body weight<br>(kg)</th>
            <th class="label-cell bg-grey text-black">Bolus<br>(units, max. dose 4000 units)</th>
            <th class="label-cell bg-grey text-black">Bolus volume</th>
            <th class="label-cell bg-grey text-black">Starting infusion<br>(units/hr, max. 1000 units/hr)</th>
            <th class="label-cell bg-grey text-black">Starting infusion<br>(mL/hr)</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td class="label-cell">36-39</td>
            <td class="label-cell">2250</td>
            <td class="label-cell">4.5</td>
            <td class="label-cell">450</td>
            <td class="label-cell">0.9</td>
        </tr>
        <tr>
            <td class="label-cell">40-43</td>
            <td class="label-cell">2500</td>
            <td class="label-cell">5</td>
            <td class="label-cell">500</td>
            <td class="label-cell">1.0</td>
        </tr>
        <tr>
            <td class="label-cell">44-47</td>
            <td class="label-cell">2750</td>
            <td class="label-cell">5.5</td>
            <td class="label-cell">550</td>
            <td class="label-cell">1.1</td>
        </tr>
        <tr>
            <td class="label-cell">48-51</td>
            <td class="label-cell">3000</td>
            <td class="label-cell">6</td>
            <td class="label-cell">600</td>
            <td class="label-cell">1.2</td>
        </tr>
        <tr>
            <td class="label-cell">52-55</td>
            <td class="label-cell">3250</td>
            <td class="label-cell">6.5</td>
            <td class="label-cell">650</td>
            <td class="label-cell">1.3</td>
        </tr>
        <tr>
            <td class="label-cell">56-59</td>
            <td class="label-cell">3500</td>
            <td class="label-cell">7</td>
            <td class="label-cell">700</td>
            <td class="label-cell">1.4</td>
        </tr>
        <tr>
            <td class="label-cell">60-63</td>
            <td class="label-cell">3750</td>
            <td class="label-cell">7.5</td>
            <td class="label-cell">750</td>
            <td class="label-cell">1.5</td>
        </tr>
        <tr>
            <td class="label-cell">64-67</td>
            <td class="label-cell">4000</td>
            <td class="label-cell">8</td>
            <td class="label-cell">800</td>
            <td class="label-cell">1.6</td>
        </tr>
        <tr>
            <td class="label-cell">68-72</td>
            <td class="label-cell">4000</td>
            <td class="label-cell">8</td>
            <td class="label-cell">850</td>
            <td class="label-cell">1.7</td>
        </tr>
        <tr>
            <td class="label-cell">73-77</td>
            <td class="label-cell">4000</td>
            <td class="label-cell">8</td>
            <td class="label-cell">900</td>
            <td class="label-cell">1.8</td>
        </tr>
        <tr>
            <td class="label-cell">78-82</td>
            <td class="label-cell">4000</td>
            <td class="label-cell">8</td>
            <td class="label-cell">950</td>
            <td class="label-cell">1.9</td>
        </tr>
        <tr>
            <td class="label-cell">Greater than 82</td>
            <td class="label-cell">4000</td>
            <td class="label-cell">8</td>
            <td class="label-cell">1000</td>
            <td class="label-cell">2.0</td>
        </tr>
        </tbody>
    </table>
    </div>

    <f7-list media-list class="mt-0 mb-0">

      <html-list-item
        v-for="item in questions2"
        :checkbox="!(item.isDivider||item.isSecondary)"
        :divider="item.isDivider"
        :name="item.name"
        :key="item.id"
        :value="item.id"
        
        :checked="getAnswer(item.id)===true"
        @change="setAnswerCheckbox"
        :title="item.title"
        :subtitle="item.subtitle"
        :text="item.text"
        :class="item.class"
      ></html-list-item>

    </f7-list>

    <div class="data-table card wrap-cell">
    <table>
        <thead>
        <tr>
            <th colspan="2" style="border-bottom: solid 1px #ccc !important;" class="label-cell bg-grey text-black text-center">Indication: UAP/NSTEMI</th>
        </tr>
        <tr>
            <th class="label-cell bg-grey text-black" width="50%">Normal dosing<br>(CrCl greater than 30 mL/min)</th>
            <th class="label-cell bg-grey text-black" width="50%">Renal impairment<br>(CrCl less than or equal to 30 mL/min)</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td class="label-cell">
                <p class="para">Enoxaparin (Clexane®): 1 mg/kg <strong>TWICE</strong> a day
                <br>(Maximum dose 80 mg. If weight > 100 kg discuss with oncall cardiologist).</p>
                <p class="para">[If equal to or older than 75 years, use 1 mg/kg daily after discussion with cardiologist]</p>
                <p class="para">OR</p>
                <p class="para">Modified dose IV unfractionated heparin infusion</p>
                <p class="para">+/- intial bolus [discuss with cardiologist]</p>
                <p class="para mb-0">See section 3.6</p>
            </td>
            <td class="label-cell">
                <p class="para">Use modified dose IV unfractionated heparin infusion +/- initial bolus [discuss with cardiologist].</p>
                <p class="para mb-0">Section 3.6</p>
            </td>
        </tr>
        </tbody>
    </table>
    </div>

  </f7-page>
</template>

<script>
  import HtmlListItem from './html-list-item.vue';
  import helpers from './helpers.vue';
  export default {
    extends: helpers,
    mounted() {
      document.title = 'Anticoagulation - Blacktown Hospital PACSA'
    },
    data() {
      return {
        section: 'anticoagulation',
        questions: [
            { id: 'anticoagulation-01', name: 'anticoagulation_block', title: '<strong>IV heparin infusion for NSTEMI or post thrombolysis</strong>', isSecondary: true },
            { id: 'anticoagulation-02', name: 'anticoagulation_block', title: '<p class="para">Preparation of Unfractionated Heparin Syringe</p><p class="para">For all patients prepare unfractionated heparin syringe as follows:</p><p class="para">(heparin sodium 25,000 units per 50 mL of sodium chloride 0.9%)<ul class="list-norm"><li>Add <strong>5 mL</strong> of heparin sodium (5,000 units / 1 mL strength ampoules) to 45 mL of sodium chloride 0.9%. Mix well. Final volume = 50 mL. Use a syringe pump.</li><li>Heparin concentration = 500 units per mL (100 units per 0.2 mL).</li><li>On initiation</li><li><strong>60 units / kg bolus IV (maximum dose 4000 units)</strong></li><li><strong>Followed by an IV infusion of 12 units / kg / hour (maximum rate 1000 units / hour or 2 mL / hr).</strong></li><li>Coagulation studies should be performed after <strong>3 hours</strong> to achieve an APTT between <strong>60-80 seconds (s)</strong> and the infusion rate adjusted accordingly.</li><li class="mb-0">The infusion should be continued for at least 48 hours.</li></ul>', isSecondary: true },
            { id: 'anticoagulation-03', name: 'anticoagulation_block', title: '<strong>Table 1 (Section 3.6). Cardiology modified dose unfractionated heparin infusion</strong>', isSecondary: true, class: 'bg-grey' },
            { id: 'anticoagulation-04', name: 'anticoagulation_block', title: '<p class="para"><strong>Heparin initial IV bolus and infusion rates (Cardiology modified protocol)</strong></p><p class="para mb-0" style="color:#c13633;"><strong><em>Heparin concentration = 25,000 units per 50 mL (500 units per mL)</em></strong></p>', isSecondary: true },
        ],
        questions2: [
            { id: 'anticoagulation-05', name: 'anticoagulation_block', title: '<strong>Enoxaparin/Clexane Dosing for NSTEMI</strong>', isSecondary: true, class: 'bg-grey' },
            { id: 'anticoagulation-06', name: 'anticoagulation_block', title: '<strong><u>Table 1 (section 3.2)</u> – Dosing regimen for anticoagulants: Unstable Angina Pectoris/Non ST Elevated Myocardial Infarction</strong>', isSecondary: true },
        ],
      }
    },
    computed: {

    },
    components: {
      HtmlListItem,
    },
  }
</script>