<template>
  <f7-page noNavbar name="stratify-acs-risk-low">

    <f7-block strong class="low-risk-block mb-0 mt-0">
      <h3 class="mb-1 color-white">Low Risk</h3>
      <div><em>If you select <strong>ALL</strong> of the following you will be taken straight to <strong>LOW RISK</strong> recommended further management</em></div>
    </f7-block>

    <f7-list media-list class="mt-0 mb-0">
      <html-list-item
        v-for="item in questions"
        checkbox
        :name="item.name"
        :key="item.id"
        :value="item.id"
        :checked="getAnswer(item.id)===true"
        @change="setAnswerCheckboxUncheckNone"
        :title="item.title"
        :subtitle="item.subtitle"
        :text="item.text"
      ></html-list-item>
    </f7-list>

    <f7-block strong class="mb-0 mt-0">
      
    </f7-block>

    <f7-list media-list class="mt-0 mb-0">
      <html-list-item
        key="acslNone"
        id="acslNone"
        value="acslNone"
        name="acs_risk"
        checkbox
        :checked="getAnswer('acslNone')===true"
        @change="checkNone"
        title="None of the above"
      ></html-list-item>
    </f7-list>

    <f7-toolbar bottom>
      <div></div>
      <f7-link :class="{disabled: !( someAnswers(['acslNone']) || everyAnswers(['acsl1','acsl2','acsl3']) ) }" :href="someAnswers(['acsl1','acsl2','acsl3'])?nextPageEvery:nextPageNone">Next<f7-icon size="20px" ios="f7:chevron_right"></f7-icon></f7-link>
    </f7-toolbar>
    
  </f7-page>
</template>

<script>
  import HtmlListItem from './html-list-item.vue';
  import helpers from './helpers.vue';
  export default {
    extends: helpers,
    mounted() {
      document.title = 'Low Risk - Stratify - Blacktown Hospital PACSA'
    },
    data() {
      return {
        section: 'acs_risk_low',
        nextPageEvery: '/management-risk-low/',
        nextPageNone: '/stratify-acs-risk-intermediate/',
        questions: [
          { id: 'acsl1', name: 'acs_risk', title: 'Normal <strong>ECG</strong> / or Minor changes', subtitle: '(T inversion ≤1mm or ST depression &lt;0.5mm)' },
          { id: 'acsl2', name: 'acs_risk', title: '<strong>HS troponin I</strong> ≤ 50ng/L' },
          { id: 'acsl3', name: 'acs_risk', title: 'Currently pain free' },
        ],
      }
    },
    components: {
      HtmlListItem,
    },
    methods: {
      setAnswerCheckboxUncheckNone: function(event) {
        this.uncheckById("acslNone");
        this.setAnswerCheckbox(event);
      },
      checkNone: function(event) {
        this.setAnswerCheckbox(event);
        if (event.target.checked === true) {
          for (var item of this.questions) {
            this.uncheckById(item.id);
          }
        }
      }
    },
  }
</script>