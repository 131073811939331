<template>
  <f7-page noNavbar name="consider-aortic-and-pulmonary">
    <f7-navbar title="About" back-link="Back"></f7-navbar>

    <f7-block strong>
      <h2>Consider:</h2>
    </f7-block>

    <f7-list>
      <f7-list-item
        @change="checkBoxChange($event.target.value)"
        :checked="this.$store.state.consider_aortic_and_pulmonary.indexOf('Aortic Dissection') >= 0"
        checkbox
        title="Aortic Dissection"
        value="Aortic Dissection"
        name="consider_2"
      ></f7-list-item>
      <f7-list-item
        @change="checkBoxChange($event.target.value)"
        :checked="this.$store.state.consider_aortic_and_pulmonary.indexOf('Pulmonary Embolism') >= 0"
        checkbox
        title="Pulmonary Embolism"
        value="Pulmonary Embolism"
        name="consider_2"
      ></f7-list-item>
    </f7-list>

    <f7-toolbar bottom>
      <div></div>
      <f7-link href="#">Next step</f7-link>
    </f7-toolbar>
  </f7-page>
</template>

<script>
export default {
  mounted() {
    document.title = 'Consider aortic or pulmonary - Blacktown Hospital PACSA'
  },
  methods: {
    checkBoxChange: function(value) {
      //this.$store.dispatch('addECGItem', value);
    }
  }
};
</script>
