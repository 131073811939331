<template>
  <f7-page noNavbar name="stratify-acs-risk-low">

    <f7-block strong class="low-risk-block mb-0 mt-0">
      <h3 class="mb-1 color-white">Low Risk</h3>
      <div>Recommended Further Management</div>
    </f7-block>

    <!-- start data driven list -->

    <f7-list media-list class="mb-0 mt-0">
      <html-list-item
        v-for="item in questions"
        :checkbox="!(item.isDivider||item.isSecondary)"
        :divider="item.isDivider"
        :name="item.name"
        :key="item.id"
        :value="item.id"
        :checked="getAnswer(item.id)===true"
        @change="setAnswerCheckbox"
        :title="item.useEdacsTitle === true ? edacsTitle : item.title"
        :subtitle="item.subtitle"
        :class="item.class"
      ></html-list-item>
    </f7-list>

  </f7-page>
</template>

<script>
  import HtmlListItem from './html-list-item.vue';
  import helpers from './helpers.vue';
  export default {
    extends: helpers,
    mounted() {
      document.title = 'Low Risk - Management - Blacktown Hospital PACSA'
    },
    data() {
      return {
        section: 'management-risk-low',
        questions: [
          { id: 'mgtl01', name: 'mgt_risk', title: '<strong>Aspirin</strong> 300mg stat' },
          { id: 'mgtl02', name: 'mgt_risk', title: '<strong>Pain relief</strong> as required' },
          { id: 'mgtl03', name: 'mgt_risk', title: 'Monitored bed not essential if no further symptoms ' },
          { id: 'mgtl04', name: 'mgt_risk', title: 'Repeat <strong>troponin</strong> and ECG at <strong>2hr</strong>' },
          // { id: 'mgtld01', name: 'mgt_risk', isDivider: true },
          { id: 'mgtl05a', name: 'mgt_risk', title: '<div class="block-text"><strong>IF still low risk after 2hr Trop</strong></div>', isSecondary: true, class: 'bg-grey' },
          { id: 'mgtl05', name: 'mgt_risk', title: '<p class="para">Complete Emergency Department Assessment of Chest Pain Score</p><p class="para mb-0"><a href="/edacs/" class="button button-fill">EDACS</a></p>', isSecondary: true },
          { id: 'mgtl06', name: 'mgt_risk', title: '', isSecondary: true, class: 'edacs', useEdacsTitle: true },
          // { id: 'mgtld02', name: 'mgt_risk', isDivider: true },
          { id: 'mgtl07', name: 'mgt_risk', title: '<div class="block-text"><strong>IF all of following</strong></div>', isSecondary: true, class: 'bg-grey' },
          { id: 'mgtl08', name: 'mgt_risk', title: 'If Emergency Department Assessment of Chest Pain Score <strong>EDACS &lt;16</strong>' },
          { id: 'mgtl09', name: 'mgt_risk', title: '', isSecondary: true, class: 'edacs', useEdacsTitle: true },
          { id: 'mgtl10', name: 'mgt_risk', title: '<strong>No</strong> ongoing chest pain' },
          { id: 'mgtl11', name: 'mgt_risk', title: '0 and 2 hr HS Trop both samples ≤ 50ng/L and 2h Delta is ≤15 ng/L' },
          { id: 'mgtl12', name: 'mgt_risk', title: '<strong>Alternative causes</strong> of chest pain considered and <strong>excluded</strong> (including pulmonary embolism and aortic dissection)' },
          // { id: 'mgtld03', name: 'mgt_risk', isDivider: true },
          { id: 'mgtl13', name: 'mgt_risk', title: '<div class="block-text"><strong>Then</strong></div>', isSecondary: true, class: 'bg-grey' },
          { id: 'mgtl14', name: 'mgt_risk', title: '<p class="para">Patient safe for <strong>discharge</strong> to early outpatient follow-up investigation EST or CTCA within 2 wks (or proceed to earlier inpatient testing) book at clinical measurements or cardiologist office. See P4 for guideline on non invasive testing.</p><p class="para mb-0">On call cardiologist only required to be contacted if you have any further concerns with low-risk patients.</p>', isSecondary: true },
          // { id: 'mgtl16', name: 'mgt_risk', title: '<strong>† Re-stratisfy after 2nd HS Troponin I at 3hrs after initial sample on arrival in ED</strong>', isSecondary: true, class:"bg-blue" },
          // { id: 'mgtl17', name: 'mgt_risk', title: 'Both samples &lt;17ng/L' },
        ],
      }
    },
    computed: {
      edacsTitle() {
        return `Current EDACS Score: <strong>${this.$store.getters.getEdacsScore}</strong>`;
      },
    },
    components: {
      HtmlListItem,
    },
  }
</script>