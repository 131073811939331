<template>
  <f7-page noNavbar name="stratify-acs-risk-high">

    <f7-block strong class="high-risk-block mb-0 mt-0">
      <h3 class="mb-1 color-white">High Risk</h3>
      <div>Recommended Further Management</div>
    </f7-block>

    <f7-list media-list class="mb-0 mt-0">
      <html-list-item
        v-for="item in questions"
        :checkbox="!(item.isDivider||item.isSecondary)"
        :divider="item.isDivider"
        :name="item.name"
        :key="item.id"
        :value="item.id"
        :checked="getAnswer(item.id)===true"
        @change="setAnswerCheckbox"
        :title="item.title"
        :subtitle="item.subtitle"
        :class="item.class"
      ></html-list-item>
    </f7-list>

  </f7-page>
</template>

<script>
  import HtmlListItem from './html-list-item.vue';
  import helpers from './helpers.vue';
  export default {
    extends: helpers,
    mounted() {
      document.title = 'High Risk - Management - Blacktown Hospital PACSA'
    },
    data() {
      return {
        section: 'management-risk-high',
        questions: [
          { id: 'mgth01', name: 'mgt_risk', title: '1. <strong>Monitored</strong> bed' },
          { id: 'mgth02', name: 'mgt_risk', title: '2. <strong>Aspirin</strong> 300mg stat then 100mg daily' },
          { id: 'mgth03', name: 'mgt_risk', title: '3. <strong>Second antiplatelet</strong>;' },
          { id: 'mgth04', name: 'mgt_risk', title: '<div class="block-text">If <u>not</u> on oral anticoagulant, e.g. warfarin '+
          '<strong>Ticagrelor</strong> 180mg stat then 90mg BD</div>', isSecondary: true, class: 'bg-grey' },
          { id: 'mgth05', name: 'mgt_risk', title: '<div class="block-text">If <u>on</u> oral anticoagulant then<br>'+
          '<strong>Clopidogrel</strong> 600mg then 75mg daily</div>', isSecondary: true, class: 'bg-grey' },
          { id: 'mgth06', name: 'mgt_risk', title: '4. <strong>Anticoagulation</strong>;' },
          { id: 'mgth06a', name: 'mgt_risk', title: '<div class="block-text">If <u>not</u> on oral anticoagulant, e.g. warfarin/NOAC', isSecondary: true, class: 'bg-grey' },
          { id: 'mgth07', name: 'mgt_risk', title: '<div class="block-text"><strong>IV heparin</strong> bolus and infusion per anticoagulation '+
          'guidelines, unless instructed by on call cardiologist to give enoxaparin 1mg/kg/ bd.</div>', isSecondary: true, class: 'bg-grey' },
          { id: 'mgth08', name: 'mgt_risk', title: '5. <strong>Beta blocker*</strong> metoprolol 25-50mg BD omit if HR&lt;60' },
          { id: 'mgth09', name: 'mgt_risk', title: '6. <strong>Statin*</strong> rosuvastatin 20mg daily or atorvastatin 40mg daily' },
          { id: 'mgth10', name: 'mgt_risk', title: '7. <strong>ACE inhibitor*</strong> ramipril 2.5mg daily or perindopril 2mg daily or ARB if ACE intolerant (e.g. candesartan 4mg daily or irbesartan 75mg daily) omit if renal impairment' },
          { id: 'mgth11', name: 'mgt_risk', title: '8. <strong>PRN</strong>; GTN, morphine and maxolon' },
          { id: 'mgth12', name: 'mgt_risk', title: '9. <strong>Ongoing chest pain</strong>' },
          { id: 'mgth13', name: 'mgt_risk', title: '<ol type="a" class="question-list">'+
          '<li>Repeat ECG</li>'+
          '<li>IF dynamic ECG changes contact cardiologist <strong>to decide on</strong> IV tirofiban and urgent angiogram</li>'+
          '<li style="margin-bottom:0;">If No changes GTN/Morphine consider IV GTN</li>'+
          '</ol>', isSecondary: true },
          { id: 'mgth14', name: 'mgt_risk', title: '10. Repeat <strong>troponin</strong> in <strong>2hrs</strong> re-stratify' },
          { id: 'mgth14a', name: 'mgt_risk', title: '<p class="para mb-0" style="padding-left:36px;"><a href="/troponin/" class="button button-fill">High sensitivity Troponin I</a></p>', isSecondary: true },
          { id: 'mgth15', name: 'mgt_risk', title: '11. Keep NBM until discussed with cardiology on call re <strong>angiography</strong>' },
          { id: 'mgth16', name: 'mgt_risk', title: '12. <strong>CCU</strong> bed <strong>if</strong> requiring IV GTN/tirofiban or if haemodynamically unstable' },
          { id: 'mgth17', name: 'mgt_risk', subtitle: '* Continue drug of same class if already taking (BB, Statin, ACE)', isSecondary: true, class: 'bg-grey' },
          //{ id: 'mgth17a', name: 'mgt_risk', title: '<strong>† Re-stratisfy after 2nd HS Troponin I at 3hrs after initial sample on arrival in ED</strong>', isSecondary: true, class:"bg-blue" },
          //{ id: 'mgth18', name: 'mgt_risk', title: 'Either sample &gt;17ng/L with &ge;50% change (increase or decrease)' },
        ],
      }
    },
    components: {
      HtmlListItem,
    },
  }
</script>