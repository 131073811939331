<template>
  <f7-page noNavbar name="home">

    <!-- Toolbar-->
    <f7-toolbar bottom>
      <f7-link @click="resetUser">Reset Answers</f7-link>
      <f7-link href="/intro/">Start <f7-icon size="20px" ios="f7:chevron_right"></f7-icon></f7-link>
    </f7-toolbar>
    <!-- Page content-->

          <div class="block text-center home-flex">
            <h1 class="mb-0 mt-0 home-title">Pathway for Acute Coronary Syndrome Assessment (PACSA)<br><br>Chest pain pathway 2019</h1>
          </div>

  </f7-page>
</template>

<script>
  import helpers from './helpers.vue';
  export default {
    extends: helpers,
    mounted() {
      document.title = 'Home - Blacktown Hospital PACSA'
    }
  }
</script>