<template>
  <f7-page noNavbar name="lbbb-criteria">
    <f7-block class="text-center">
      <p class="mb-0 med-page-title"><strong>LBBB Criteria</strong></p>
      <div><em>(new or presumed new)</em></div>
    </f7-block>

    <template v-for="item in questions">
      <f7-list media-list class="mt-0 mb-0" :key="item.key">
        <html-list-item
          :checkbox="!(item.isDivider || item.isSecondary)"
          :divider="item.isDivider"
          :name="item.name"
          :key="item.id"
          :value="item.id"
          :checked="getAnswer(item.id) === true"
          @change="setAnswerCheckbox"
          :title="item.useEdacsTitle === true ? edacsTitle : item.title"
          :subtitle="item.subtitle"
          :text="item.text"
          :class="item.class"
        ></html-list-item>
        <html-list-item
          class="bg-tan"
          v-if="item.action && getAnswer(item.id) === true"
          :key="item.id + '-action'"
          :title="item.action"
        ></html-list-item>
      </f7-list>
    </template>

    <f7-toolbar bottom>
      <div></div>
      <f7-link href="/st-elevation-yes/"
        >Next step <f7-icon size="20px" ios="f7:chevron_right"></f7-icon
      ></f7-link>
    </f7-toolbar>
  </f7-page>
</template>

<script>
  import HtmlListItem from "./html-list-item.vue";
  import helpers from "./helpers.vue";
  export default {
    extends: helpers,
    mounted() {
      document.title = 'LBBB Criteria - Blacktown Hospital PACSA'
    },
    data() {
      return {
        section: "lbbb-criteria",
        questions: [
          {
            id: "lbbb-crit-01",
            name: "lbbb_crit",
            title:
              `<p class="para">New onset LBBB in a stable patient with chest pain is no longer an indication for reperfusion.</p>
               <p class="para mb-0">Urgent reperfusion is indicated if LBBB and a strong clinical suspicion of ongoing ischemia.</p>`,
            isSecondary: true
          },
          {
            id: "lbbb-crit-01a",
            name: "lbbb_crit",
            title: "<strong>One of</strong>",
            isSecondary: true,
            class: "bg-grey"
          },
          {
            id: "lbbb-crit-02",
            name: "lbbb_crit",
            title: "LBBB and Hemodynamically unstable  "
          },
          {
            id: "lbbb-crit-03",
            name: "lbbb_crit",
            title:
              "LBBB and Hemodynamically stable with positive modified Sgarbossa criteria"
          },
          {
            id: "lbbb-crit-03a",
            name: "lbbb_crit",
            title:
              `<p class="para" style="padding-left:36px;padding-right:36px;"><strong>Diagnosis of STEMI in Left bundle branch block (LBBB) using modified Sgarbossa criteria</strong></p>
               <p class="para" style="padding-left:36px;padding-right:36px;margin-bottom:20px;">
                 1. Any lead with > 1mm concordant ST elevation (QRS and ST in same direction)<br><strong>OR</strong><br>
                 2. Any lead in  V1-V3 with > 1mm concordant ST depression (QRD and ST in same direction)<br><strong>OR</strong><br>
                 3. Any lead with ST elevation more than 25% of a preceding S wave</p>
               <p class="para" style="padding-left:36px;padding-right:36px;margin-bottom:20px;"><img width="400" src="static/images/sgarbossa_criteria.png" style="max-width:100%;display:block;"></p>
               <p class="para mb-0" style="padding-left:36px;padding-right:36px;"><a href="https://litfl.com/sgarbossa-criteria-ecg-library/" class="button button-fill external">More information</a></p>`,
            isSecondary: true,
            class: "bg-tan"
          }
        ]
      };
    },
    components: {
      HtmlListItem
    }
  };
</script>
