<template>
  <f7-page noNavbar name="home">

    <!-- Toolbar-->
    <f7-toolbar bottom>
      <div></div>
      <f7-link href="/ecg-and-vital-signs/">Next step <f7-icon size="20px" ios="f7:chevron_right"></f7-icon></f7-link>
    </f7-toolbar>
    <!-- Page content-->

          <div class="block text-center">
            <h3 class="mb-0 mt-0">CHEST PAIN</h3>
            <div class="text-separator"><em>or</em></div>
            <h3 class="mt-0 mb-1">OTHER SYMPTOMS OF MYOCARDIAL ISCHAEMIA</h3>
            <div><em>e.g sweating, sudden orthopnea, syncope, dyspnoea, epigastric discomfort, jaw pain, arm pain</em></div>
          </div>

          <div class="block block-strong text-center">
            <h3 class="mt-0" style="margin-bottom:5px;color:#3261aa;">BE AWARE:</h3>
            <h4 class="mt-0" style="margin-bottom:5px;color:#c83078;">HIGH RISK ATYPICAL PRESENTATIONS</h4>
            <div><em>e.g diabetes, renal failure, chronic renal disease, HIV, female, elderly or Aboriginal</em></div>
          </div>

          <div class="block text-center">
            <h3 class="mb-0 mt-0">TRIAGE CATEGORY<br><span class="large">2</span></h3>
          </div>

  </f7-page>
</template>

<script>
  export default {
    mounted() {
      document.title = 'Intro - Blacktown Hospital PACSA'
    }
  }
</script>