<template>
  <f7-page noNavbar name="hs-trop-calculator">

    <f7-block class="text-center">
      <p class="mb-0 med-page-title"><strong>HS Trop I calculator<br>BTMD Siemens Vista HS assay</strong></p>
    </f7-block>

    <f7-list media-list class="mt-0 mb-0">
        <f7-list-input
        label="Enter 0 hour troponin (ng/L)"
        type="number"
        :name="trop_calc_01.name"
        :value="getAnswer(trop_calc_01.id)"
        :key="trop_calc_01.id"
        :input-id="trop_calc_01.id"
        @input="setAnswerTextInput"
        placeholder=""
        outline
        floating-label
        clear-button
        ></f7-list-input>

        <f7-block class="text-center high-risk-block mt-0 mb-0" style="padding-top:15px;padding-bottom:15px;" v-show="getAnswer('trop_calc_01')>50&&getAnswer('trop_calc_01')!=''">
            >50ng/L<br>
            <strong>High risk repeat at 2h hours</strong>
        </f7-block>
        <f7-block class="text-center low-risk-block mt-0 mb-0" style="padding-top:15px;padding-bottom:15px;" v-show="getAnswer('trop_calc_01')<=50&&getAnswer('trop_calc_01')>=5&&getAnswer('trop_calc_01')!=''">
            ≤ 50ng/L<br>
            <strong>Low risk repeat at 2h hours</strong>
        </f7-block>
        <f7-block class="text-center low-risk-block mt-0 mb-0" style="padding-top:15px;padding-bottom:15px;" v-show="getAnswer('trop_calc_01')<5&&getAnswer('trop_calc_01')!=''">
            <5ng/l<br>
            <strong>Very low risk repeat at 2 hours</strong>
        </f7-block>

    </f7-list>

    <f7-list media-list class="mt-0 mb-0">
      <f7-list-input
      label="Enter 2 hour troponin (ng/L)"
      type="number"
      :name="trop_calc_02.name"
      :value="getAnswer(trop_calc_02.id)"
      :key="trop_calc_02.id"
      :input-id="trop_calc_02.id"
      @input="setAnswerTextInput"
      placeholder=""
      outline
      floating-label
      clear-button
      ></f7-list-input>
        <f7-block class="mt-0 mb-0" style="padding-top:10px;padding-bottom:10px;" v-show="getAnswer('trop_calc_01')!=''&&getAnswer('trop_calc_02')!=''">
            2hr Delta = <strong>{{ tropDelta }} ng/L</strong>
        </f7-block>


        <f7-block class="text-center high-risk-block mt-0 mb-0" style="padding-top:15px;padding-bottom:15px;" v-show="(getAnswer('trop_calc_01')>50 || getAnswer('trop_calc_02')>50 ) || tropDelta > 15 && (getAnswer('trop_calc_01')!=''&&getAnswer('trop_calc_02')!='')" :class="{hidden: (getAnswer('trop_calc_01')>50 || getAnswer('trop_calc_02')>50 ) && tropDelta <= 15 && (getAnswer('trop_calc_01')!=''&&getAnswer('trop_calc_02')!='')}">
            Either sample >50ng/L or 2h Delta is >15 ng/L<br>
            <strong>High risk</strong>
        </f7-block>

        <f7-block class="text-center high-risk-block mt-0 mb-0" style="padding-top:15px;padding-bottom:15px;" v-show="(getAnswer('trop_calc_01')>50 || getAnswer('trop_calc_02')>50 ) && tropDelta <= 15 && (getAnswer('trop_calc_01')!=''&&getAnswer('trop_calc_02')!='')">
            Either sample >50ng/L and 2h Delta is ≤15 ng/L<br>
            <strong>High risk but not necessarily due to acute coronary syndrome consider <a style="color:#fff;text-decoration:underline;" href="/alternative-causes-of-troponin-elevation/">alternative causes of elevated troponin</a></strong>
        </f7-block>

        <f7-block class="text-center low-risk-block mt-0 mb-0" style="padding-top:15px;padding-bottom:15px;" v-show="(getAnswer('trop_calc_01')<=50 && getAnswer('trop_calc_02')<=50 ) && tropDelta <= 15 && (getAnswer('trop_calc_01')!=''&&getAnswer('trop_calc_02')!='')" :class="{hidden: getAnswer('trop_calc_01')<5 && tropDelta < 3 && (getAnswer('trop_calc_01')!=''&&getAnswer('trop_calc_02')!='')}">
            Both samples ≤ 50ng/L and 2h Delta is ≤15 ng/L<br>
            <strong>Low risk (If symptoms occurred >3hours before presentation. If symptoms occurred <3 hours repeat further sample at 3hours after initial sample.)</strong>
        </f7-block>

        <f7-block class="text-center low-risk-block mt-0 mb-0" style="padding-top:15px;padding-bottom:15px;" v-show="getAnswer('trop_calc_01')<5 && tropDelta < 3 && (getAnswer('trop_calc_01')!=''&&getAnswer('trop_calc_02')!='')">
            Initial sample <5ng/l and 2h delta <3ng/L<br>
            <strong>Very low risk (If symptoms occurred >3hours before presentation. If symptoms occurred <3 hours repeat further sample at 3hours after initial sample.)</strong>
        </f7-block>

    </f7-list>

  </f7-page>
</template>

<script>
  import HtmlListItem from './html-list-item.vue';
  import helpers from './helpers.vue';
  export default {
    extends: helpers,
    mounted() {
      document.title = 'HS Trop I calculator - Blacktown Hospital PACSA'
    },
    data() {
      return {
        section: 'trop_calculator',
        trop_calc_01: { id: 'trop_calc_01', name: 'trop_calc', value: '' },
        trop_calc_02: { id: 'trop_calc_02', name: 'trop_calc', value: '' },
      }
    },
    computed: {
      tropDelta() {
        return this.$store.getters.getTropDelta;
      },
    },
    components: {
      HtmlListItem,
    },
  }
</script>