<template>
  <f7-page noNavbar name="alternative-causes-of-troponin-elevation">

    <f7-block class="text-center">
      <p class="mb-0 med-page-title"><strong>Alternative causes of troponin elevation</strong></p>
    </f7-block>

    <f7-list media-list class="mt-0 mb-0">
      <html-list-item
        v-for="item in questions"
        :checkbox="!(item.isDivider||item.isSecondary)"
        :divider="item.isDivider"
        :name="item.name"
        :key="item.id"
        :value="item.id"
        
        :checked="getAnswer(item.id)===true"
        @change="setAnswerCheckbox"
        :title="item.title"
        :subtitle="item.subtitle"
        :text="item.text"
        :class="item.class"
      ></html-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
  import HtmlListItem from './html-list-item.vue';
  import helpers from './helpers.vue';
  export default {
    extends: helpers,
    mounted() {
      document.title = 'Alternative causes of troponin elevation - Blacktown Hospital PACSA'
    },
    data() {
      return {
        section: 'alternative-causes-of-troponin-elevation',
        questions: [
            { id: 'alt-trop-01', name: 'alt_trop', title: '<p class="para mb-0">Solitary elevations of troponin cannot be assumed to be due to MI, because troponin elevations can be due to non-ischemic causes:</p>', isSecondary: true, class: 'bg-grey' },
            { id: 'alt-trop-02', name: 'alt_trop', title: 'Tachyarrhythmia' },
            { id: 'alt-trop-03', name: 'alt_trop', title: 'Hypotension or hypertension' },
            { id: 'alt-trop-04', name: 'alt_trop', title: 'Cardiac trauma' },
            { id: 'alt-trop-05', name: 'alt_trop', title: 'Heart failure/ Cardiomyopathy' },
            { id: 'alt-trop-06', name: 'alt_trop', title: 'Myocarditis and pericarditis' },
            { id: 'alt-trop-07', name: 'alt_trop', title: 'Pulmonary embolism' },
            { id: 'alt-trop-08', name: 'alt_trop', title: 'Sepsis' },
            { id: 'alt-trop-09', name: 'alt_trop', title: 'Burns' },
            { id: 'alt-trop-10', name: 'alt_trop', title: 'Respiratory failure' },
            { id: 'alt-trop-11', name: 'alt_trop', title: 'Renal failure' },
            { id: 'alt-trop-12', name: 'alt_trop', title: 'Acute neurological diseases' },
            { id: 'alt-trop-13', name: 'alt_trop', title: 'Drug toxicity (e.g. Cocaine/Amphetamines or cancer chemotherapy)' },
            { id: 'alt-trop-14', name: 'alt_trop', title: 'Chronic elevations can result from structural cardiac abnormalities such as LV hypertrophy or ventricular dilatation and are also common in patients with renal insufficiency' },
            { id: 'alt-trop-15', name: 'alt_trop', title: 'Troponin elevations may persist for up to 14 days' },
            
        ],
      }
    },
    computed: {

    },
    components: {
      HtmlListItem,
    },
  }
</script>