<template>
  <f7-page noNavbar name="thrombolysis">

    <f7-block class="text-center">
      <p class="mb-0 med-page-title"><strong>Thrombolysis</strong></p>
    </f7-block>

    <f7-list media-list class="mt-0 mb-0">
      <html-list-item
        v-for="item in questions1"
        :checkbox="!(item.isDivider||item.isSecondary)"
        :divider="item.isDivider"
        :name="item.name"
        :key="item.id"
        :value="item.id"
        
        :checked="getAnswer(item.id)===true"
        @change="setAnswerCheckbox"
        :title="item.title"
        :subtitle="item.subtitle"
        :text="item.text"
        :class="item.class"
      ></html-list-item>

      <f7-list-input
      label="Time given (eg. 12.30pm)"
      type="text"
      :name="throm_time_given.name"
      :value="getAnswer(throm_time_given.id)"
      :key="throm_time_given.id"
      :input-id="throm_time_given.id"
      @input="setAnswerTextInput"
      placeholder=""
      outline
      floating-label
      clear-button
      ></f7-list-input>

    </f7-list>

    <div class="data-table card wrap-cell">
    <table>
        <thead>
        <tr>
            <th class="label-cell bg-grey text-black" width="33.3333333333%">Weight</th>
            <th class="label-cell bg-grey text-black" width="33.3333333333%">18-74 years</th>
            <th class="label-cell bg-grey text-black" width="33.3333333333%">18-74 years</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td class="label-cell">
              <f7-list media-list class="mt-0 mb-0">
                <html-list-item
                  :checkbox="!(weight1.isDivider||weight1.isSecondary)"
                  :divider="weight1.isDivider"
                  :name="weight1.name"
                  :key="weight1.id"
                  :value="weight1.id"
                  
                  :checked="getAnswer(weight1.id)===true"
                  @change="setAnswerCheckbox"
                  :title="weight1.title"
                  :subtitle="weight1.subtitle"
                  :text="weight1.text"
                  :class="weight1.class"
                ></html-list-item>
              </f7-list>
            </td>
            <td class="label-cell">
              <f7-list media-list class="mt-0 mb-0">
                <html-list-item
                  :checkbox="!(midyear1.isDivider||midyear1.isSecondary)"
                  :divider="midyear1.isDivider"
                  :name="midyear1.name"
                  :key="midyear1.id"
                  :value="midyear1.id"
                  
                  :checked="getAnswer(midyear1.id)===true"
                  @change="setAnswerCheckbox"
                  :title="midyear1.title"
                  :subtitle="midyear1.subtitle"
                  :text="midyear1.text"
                  :class="midyear1.class"
                ></html-list-item>
              </f7-list>
            </td>
            <td class="label-cell">
              <f7-list media-list class="mt-0 mb-0">
                <html-list-item
                  :checkbox="!(endyear1.isDivider||endyear1.isSecondary)"
                  :divider="endyear1.isDivider"
                  :name="endyear1.name"
                  :key="endyear1.id"
                  :value="endyear1.id"
                  
                  :checked="getAnswer(endyear1.id)===true"
                  @change="setAnswerCheckbox"
                  :title="endyear1.title"
                  :subtitle="endyear1.subtitle"
                  :text="endyear1.text"
                  :class="endyear1.class"
                ></html-list-item>
              </f7-list>
            </td>
        </tr>
        <tr>
            <td class="label-cell">
              <f7-list media-list class="mt-0 mb-0">
                <html-list-item
                  :checkbox="!(weight2.isDivider||weight2.isSecondary)"
                  :divider="weight2.isDivider"
                  :name="weight2.name"
                  :key="weight2.id"
                  :value="weight2.id"
                  
                  :checked="getAnswer(weight2.id)===true"
                  @change="setAnswerCheckbox"
                  :title="weight2.title"
                  :subtitle="weight2.subtitle"
                  :text="weight2.text"
                  :class="weight2.class"
                ></html-list-item>
              </f7-list>
            </td>
            <td class="label-cell">
              <f7-list media-list class="mt-0 mb-0">
                <html-list-item
                  :checkbox="!(midyear2.isDivider||midyear2.isSecondary)"
                  :divider="midyear2.isDivider"
                  :name="midyear2.name"
                  :key="midyear2.id"
                  :value="midyear2.id"
                  
                  :checked="getAnswer(midyear2.id)===true"
                  @change="setAnswerCheckbox"
                  :title="midyear2.title"
                  :subtitle="midyear2.subtitle"
                  :text="midyear2.text"
                  :class="midyear2.class"
                ></html-list-item>
              </f7-list>
            </td>
            <td class="label-cell">
              <f7-list media-list class="mt-0 mb-0">
                <html-list-item
                  :checkbox="!(endyear2.isDivider||endyear2.isSecondary)"
                  :divider="endyear2.isDivider"
                  :name="endyear2.name"
                  :key="endyear2.id"
                  :value="endyear2.id"
                  
                  :checked="getAnswer(endyear2.id)===true"
                  @change="setAnswerCheckbox"
                  :title="endyear2.title"
                  :subtitle="endyear2.subtitle"
                  :text="endyear2.text"
                  :class="endyear2.class"
                ></html-list-item>
              </f7-list>
            </td>
        </tr>
        <tr>
            <td class="label-cell">
              <f7-list media-list class="mt-0 mb-0">
                <html-list-item
                  :checkbox="!(weight3.isDivider||weight3.isSecondary)"
                  :divider="weight3.isDivider"
                  :name="weight3.name"
                  :key="weight3.id"
                  :value="weight3.id"
                  
                  :checked="getAnswer(weight3.id)===true"
                  @change="setAnswerCheckbox"
                  :title="weight3.title"
                  :subtitle="weight3.subtitle"
                  :text="weight3.text"
                  :class="weight3.class"
                ></html-list-item>
              </f7-list>
            </td>
            <td class="label-cell">
              <f7-list media-list class="mt-0 mb-0">
                <html-list-item
                  :checkbox="!(midyear3.isDivider||midyear3.isSecondary)"
                  :divider="midyear3.isDivider"
                  :name="midyear3.name"
                  :key="midyear3.id"
                  :value="midyear3.id"
                  
                  :checked="getAnswer(midyear3.id)===true"
                  @change="setAnswerCheckbox"
                  :title="midyear3.title"
                  :subtitle="midyear3.subtitle"
                  :text="midyear3.text"
                  :class="midyear3.class"
                ></html-list-item>
              </f7-list>
            </td>
            <td class="label-cell">
              <f7-list media-list class="mt-0 mb-0">
                <html-list-item
                  :checkbox="!(endyear3.isDivider||endyear3.isSecondary)"
                  :divider="endyear3.isDivider"
                  :name="endyear3.name"
                  :key="endyear3.id"
                  :value="endyear3.id"
                  
                  :checked="getAnswer(endyear3.id)===true"
                  @change="setAnswerCheckbox"
                  :title="endyear3.title"
                  :subtitle="endyear3.subtitle"
                  :text="endyear3.text"
                  :class="endyear3.class"
                ></html-list-item>
              </f7-list>
            </td>
        </tr>
        <tr>
            <td class="label-cell">
              <f7-list media-list class="mt-0 mb-0">
                <html-list-item
                  :checkbox="!(weight4.isDivider||weight4.isSecondary)"
                  :divider="weight4.isDivider"
                  :name="weight4.name"
                  :key="weight4.id"
                  :value="weight4.id"
                  
                  :checked="getAnswer(weight4.id)===true"
                  @change="setAnswerCheckbox"
                  :title="weight4.title"
                  :subtitle="weight4.subtitle"
                  :text="weight4.text"
                  :class="weight4.class"
                ></html-list-item>
              </f7-list>
            </td>
            <td class="label-cell">
              <f7-list media-list class="mt-0 mb-0">
                <html-list-item
                  :checkbox="!(midyear4.isDivider||midyear4.isSecondary)"
                  :divider="midyear4.isDivider"
                  :name="midyear4.name"
                  :key="midyear4.id"
                  :value="midyear4.id"
                  
                  :checked="getAnswer(midyear4.id)===true"
                  @change="setAnswerCheckbox"
                  :title="midyear4.title"
                  :subtitle="midyear4.subtitle"
                  :text="midyear4.text"
                  :class="midyear4.class"
                ></html-list-item>
              </f7-list>
            </td>
            <td class="label-cell">
              <f7-list media-list class="mt-0 mb-0">
                <html-list-item
                  :checkbox="!(endyear4.isDivider||endyear4.isSecondary)"
                  :divider="endyear4.isDivider"
                  :name="endyear4.name"
                  :key="endyear4.id"
                  :value="endyear4.id"
                  
                  :checked="getAnswer(endyear4.id)===true"
                  @change="setAnswerCheckbox"
                  :title="endyear4.title"
                  :subtitle="endyear4.subtitle"
                  :text="endyear4.text"
                  :class="endyear4.class"
                ></html-list-item>
              </f7-list>
            </td>
        </tr>
        <tr>
            <td class="label-cell">
              <f7-list media-list class="mt-0 mb-0">
                <html-list-item
                  :checkbox="!(weight5.isDivider||weight5.isSecondary)"
                  :divider="weight5.isDivider"
                  :name="weight5.name"
                  :key="weight5.id"
                  :value="weight5.id"
                  
                  :checked="getAnswer(weight5.id)===true"
                  @change="setAnswerCheckbox"
                  :title="weight5.title"
                  :subtitle="weight5.subtitle"
                  :text="weight5.text"
                  :class="weight5.class"
                ></html-list-item>
              </f7-list>
            </td>
            <td class="label-cell">
              <f7-list media-list class="mt-0 mb-0">
                <html-list-item
                  :checkbox="!(midyear5.isDivider||midyear5.isSecondary)"
                  :divider="midyear5.isDivider"
                  :name="midyear5.name"
                  :key="midyear5.id"
                  :value="midyear5.id"
                  
                  :checked="getAnswer(midyear5.id)===true"
                  @change="setAnswerCheckbox"
                  :title="midyear5.title"
                  :subtitle="midyear5.subtitle"
                  :text="midyear5.text"
                  :class="midyear5.class"
                ></html-list-item>
              </f7-list>
            </td>
            <td class="label-cell">
              <f7-list media-list class="mt-0 mb-0">
                <html-list-item
                  :checkbox="!(endyear5.isDivider||endyear5.isSecondary)"
                  :divider="endyear5.isDivider"
                  :name="endyear5.name"
                  :key="endyear5.id"
                  :value="endyear5.id"
                  
                  :checked="getAnswer(endyear5.id)===true"
                  @change="setAnswerCheckbox"
                  :title="endyear5.title"
                  :subtitle="endyear5.subtitle"
                  :text="endyear5.text"
                  :class="endyear5.class"
                ></html-list-item>
              </f7-list>
            </td>
        </tr>
        </tbody>
    </table>
    </div>


    <f7-list media-list class="mt-0 mb-0">
      <html-list-item
        v-for="item in questions2"
        :checkbox="!(item.isDivider||item.isSecondary)"
        :divider="item.isDivider"
        :name="item.name"
        :key="item.id"
        :value="item.id"
        
        :checked="getAnswer(item.id)===true"
        @change="setAnswerCheckbox"
        :title="item.title"
        :subtitle="item.subtitle"
        :text="item.text"
        :class="item.class"
      ></html-list-item>
    </f7-list>


  </f7-page>
</template>

<script>
  import HtmlListItem from './html-list-item.vue';
  import helpers from './helpers.vue';
  export default {
    extends: helpers,
    mounted() {
      document.title = 'Thrombolysis - Blacktown Hospital PACSA'
    },
    data() {
      return {
        throm_time_given: { id: 'throm_time_given', name: 'thromb_check', value: '' },

        weight1: { id: 'throm_weight1', name: 'thromb_check', title: 'Less than 60kg' },
        weight2: { id: 'throm_weight2', name: 'thromb_check', title: '60-69kg' },
        weight3: { id: 'throm_weight3', name: 'thromb_check', title: '70-79kg' },
        weight4: { id: 'throm_weight4', name: 'thromb_check', title: '80-89kg' },
        weight5: { id: 'throm_weight5', name: 'thromb_check', title: 'Above 90kg' },
        
        midyear1: { id: 'throm_midyear1', name: 'thromb_check', title: '30mg = 6mL' },
        midyear2: { id: 'throm_midyear2', name: 'thromb_check', title: '35mg = 7mL' },
        midyear3: { id: 'throm_midyear3', name: 'thromb_check', title: '40mg = 8mL' },
        midyear4: { id: 'throm_midyear4', name: 'thromb_check', title: '45mg = 9mL' },
        midyear5: { id: 'throm_midyear5', name: 'thromb_check', title: '50mg = 10mL' },
        
        endyear1: { id: 'endyear1', name: 'thromb_check', title: '15mg = 3mL' },
        endyear2: { id: 'endyear2', name: 'thromb_check', title: '17.5mg = 3.5mL' },
        endyear3: { id: 'endyear3', name: 'thromb_check', title: '20mg = 4mL' },
        endyear4: { id: 'endyear4', name: 'thromb_check', title: '22.5mg = 4.5mL' },
        endyear5: { id: 'endyear5', name: 'thromb_check', title: '25mg = 5mL' },

        section: 'thrombolysis',
        questions0: [
          {
            id: "thromb-00",
            name: "thromb_check",
            title: 'Heparin',
            subtitle:
              '<p class="para mb-0"><a href="https://litfl.com/raised-intracranial-pressure-ecg-library/" class="button button-fill button-sub external">More information</a></p>'
          },
        ],
        questions1: [
          { id: 'thromb-01', name: 'thromb_check', title: '<p class="para mb-0">Thrombolysis is only used if extended delays are expected >60m to access cath lab. <strong>Must be after discussion with on call cardiologist.</strong></p>', isSecondary: true },
          { id: 'thromb-02', name: 'thromb_check', title: '<strong>Assess Contraindications to Thrombolysis</strong>', isSecondary: true, class: 'bg-grey' },
          { id: 'thromb-03', name: 'thromb_check', title: 'Symptoms present more than 12 hours' },
          { id: 'thromb-04', name: 'thromb_check', title: 'BP more than 18/110mmHg: treat BP and reassess' },
          { id: 'thromb-05', name: 'thromb_check', title: 'Major trauma <strong>OR</strong> surgery <strong>OR</strong> internal bleeding within one month' },
          { id: 'thromb-06', name: 'thromb_check', title: 'Ischaemic stroke within 3 months <strong>OR</strong> Intracerebral bleed at any time ' },
          { id: 'thromb-07', name: 'thromb_check', title: 'Allergy to tenecteplase' },
          { id: 'thromb-08', name: 'thromb_check', title: '<span style="color:#c13633;">If any Contraindications transfer for <strong>URGENT PCI</strong></span>', isSecondary: true, class: 'bg-red' },
          { id: 'thromb-09', name: 'thromb_check', title: 'Senior Review if any Relative Contraindication: Ischaemic stroke >3 months <strong>OR</strong> INR >1.8 <strong>OR</strong> anticoagulation <strong>OR</strong> bleeding disorder' },
          { id: 'thromb-10', name: 'thromb_check', title: '<strong>Antiplatelet loading before Thrombolysis</strong>', isSecondary: true, class: 'bg-grey' },
          { id: 'thromb-11', name: 'thromb_check', title: 'Aspirin 300mg (soluble)' },
          { id: 'thromb-12', name: 'thromb_check', title: 'Already given' },
          { id: 'thromb-13', name: 'thromb_check', title: 'Contraindicated' },
          { id: 'thromb-14', name: 'thromb_check', title: '', isDivider: true },
          { id: 'thromb-15', name: 'thromb_check', title: '<strong>Agent:</strong> Clopidogrel<br><strong>18-74 years:</strong> 300mg<br><strong>75 years and over:</strong> 75mg' },
          { id: 'thromb-16', name: 'thromb_check', title: '<strong>Thrombolysis tenecteplase 5mg/mL IV</strong><br>(IV bolus over 10 sec)', isSecondary: true, class: 'bg-grey' },
        ],
        questions2: [

          { id: 'thromb-30', name: 'thromb_check', title: '<strong>Anticoagulation after Thrombolysis</strong>', isSecondary: true, class: 'bg-grey' },
          { id: 'thromb-31', name: 'thromb_check', title: '<strong>EITHER</strong>', isSecondary: true },
          { id: 'thromb-32', name: 'thromb_check', title: 'Heparin', subtitle: '15 minutes after Thrombolysis use weight based infusion (no loading dose) <strong>OR</strong> Use local protocol' },
          { id: 'thromb-32a', name: 'thromb_check', title: '<p class="para mb-0" style="padding-left:36px;padding-right:36px;"><a href="/anticoagulation/" class="button button-fill">Local protocol</a></p>', isSecondary: true },
          { id: 'thromb-33', name: 'thromb_check', title: '<strong>OR</strong>', isSecondary: true },
          { id: 'thromb-34', name: 'thromb_check', title: 'Enoxaparin' },
          { id: 'thromb-35', name: 'thromb_check', title: '<strong>IV</strong> at 15 minutes', subtitle: '<strong>18-74 years:</strong> 30mg <strong>IV</strong> bolus<br><strong>75 years and over:</strong> No <strong>IV</strong> dose' },
          { id: 'thromb-36', name: 'thromb_check', title: '<strong>AND</strong>', isSecondary: true },
          { id: 'thromb-37', name: 'thromb_check', title: '<strong>SC</strong> at 30 minutes', subtitle: '<strong>18-74 years:</strong> 1mg/kg <strong>SC</strong> (maz 100mg)<br><strong>75 years and over:</strong> 0.75mg/kg <strong>SC</strong> (max 75mg)' },

          { id: 'thromb-40', name: 'thromb_check', title: '<strong>Thrombolysis successful?</strong>', isSecondary: true, class: 'bg-grey' },
          { id: 'thromb-41', name: 'thromb_check', title: '>50% reduction in ST segment' },
          { id: 'thromb-42', name: 'thromb_check', title: 'Symptoms largely resolved' },
          { id: 'thromb-43', name: 'thromb_check', title: 'Haemodynamically stable' },
          { id: 'thromb-44', name: 'thromb_check', title: 'Thrombolysis <strong>successful</strong> if ALL three positive at 60 minutes', isSecondary: true, class: 'bg-grey' },
          { id: 'thromb-45', name: 'thromb_check', title: 'Thrombolysis <strong>NOT</strong> successful<br><strong style="color:#c13633;">URGENT PCI</strong>' },
        ],
      }
    },
    computed: {

    },
    components: {
      HtmlListItem,
    },
  }
</script>