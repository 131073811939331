<script>
// import { mapFields } from 'vuex-map-fields';
import { store } from '../js/store';

export default {
  data() {
    return {
      
    }
  },
  computed: {
    // ...mapFields([
    //   'checkObject',
    //   'checkArray',
    // ]),
    getEdacsScore: function() {
      return store.getters.getEdacsScore;
    },
  },
  methods: {
    getAnswer: function(answerId) {
      return store.getters.getSectionAnswer({section: this.section, answerId});
    },
    getSection: function(section) {
      return store.getters.getSectionAnswer({section});
    },
    // getEdacsScore: function() {
    //   return store.getters.getEdacsScore;
    // },
    sectionSomeChecked: function(event) {
      return store.getters.sectionSomeChecked(this.section);
    },
    sectionEveryChecked: function(event) {
      return store.getters.sectionEveryChecked(this.section);
    },
    setAnswerCheckbox: function(event) {
      const payload = {
        section: this.section,
        answerId: event.target.value,
        answerValue: event.target.checked,
      };
      store.commit('setSectionAnswer', payload);
    },
    setAnswerRadio: function(event) {
      let points = event.target.getAttribute('points');
      const payload = {
        section: this.section,
        answerId: event.target.name,
        answerValue: event.target.value,
        points,
      };
      store.commit('setSectionAnswer', payload);
    },
    setAnswerTextInput: function(event) {
      const payload = {
        section: this.section,
        answerId: event.target.id,
        answerValue: event.target.value,
      };
      store.commit('setSectionAnswer', payload);
    },
    onClick(event) {
      console.log(event.target);
    },
    someAnswers: function(answerIds) {
      let result = answerIds.some(key => store.getters.getSectionAnswer({section: this.section, answerId: key})=== true);
      return result
    },
    everyAnswers: function(answerIds) {
      let result = answerIds.every(key => store.getters.getSectionAnswer({section: this.section, answerId: key})=== true);
      return result
    },
    uncheckById: function(itemId) {
      let event = {
        target: {
          value: itemId,
          checked: false,
        }
      }
      this.setAnswerCheckbox(event);
    },
    resetUser() {
      if (confirm('Are you sure you want to reset your answers?')) {
          store.commit('resetUser');
      } else {
          // Do nothing!
      }
    },
  }
}
</script>