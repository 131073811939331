import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

// init local storage
const vuexPersist = new VuexPersist({
  key: 'bthsp-chestpain',
  storage: window.localStorage
})

Vue.use(Vuex);

// import { getField, updateField } from 'vuex-map-fields';

export const store = new Vuex.Store({
  state: {
    st_elevation: 'test',
    ecg_answers: [],
    consider_aortic_and_pulmonary: [],
    userId: 0,
    users: {
      0: {
        timestamp: new Date().getDate(),
        lastlocation: '/',
      }
    },
    userData: {
      0: {},
    }
  },
  mutations: {
    addECGItem(state, value) {
        state.ecg_answers.push(value);
    },
    removeECGItem(state, value) {
        var index = state.ecg_answers.indexOf(value);
        if (index > -1) {
            state.ecg_answers.splice(index, 1);
        }
    },
    // updateField,
    setUser: (state, userId) => {
      state.userId = userId;
      if (typeof state.userData[state.userId] === 'undefined') {
        state.userData[state.userId] = { };
      }
    },
    setSectionAnswer: (state, payload) => {
      let answerId = payload.answerId;
      let answerValue = payload.answerValue;
      let points = payload.points;
      let userData = state.userData[state.userId];
      let section = payload.section;
      // userData.answers = userData.answers || {};
      // userData.answers[section] = userData.answers[section] || {};
      if (typeof userData.answers === 'undefined') Vue.set(userData, 'answers', {});
      if (typeof userData.answers[section] === 'undefined') Vue.set(userData.answers, section, {});
      if (typeof userData.answers[section][answerId] === 'undefined') Vue.set(userData.answers[section], answerId, undefined);
      if (
        typeof answerValue === "string" && 
        (answerValue !== "true" || answerValue !== "false") &&
        userData.answers[section][answerId] === answerValue
      ) {
        answerValue = "";
        if (typeof points !== "undefined") points = "0";
      }
      userData.answers[section][answerId] = answerValue;
      // I'm sorry, this points thing got a bit crazy...
      if (typeof points !== "undefined") {
        let pointsSeciton = section+'-points';
        if (typeof userData.answers[pointsSeciton] === 'undefined') Vue.set(userData.answers, pointsSeciton, {});
        if (typeof userData.answers[pointsSeciton][answerId] === 'undefined') Vue.set(userData.answers[pointsSeciton], answerId, "0");
        userData.answers[pointsSeciton][answerId] = points;
      }
    },
    resetUser(state) {
      state.userData[state.userId] = {};
    },
  },
  actions: {
    
  },
  getters: {
    // getField,
    getEdacsScore: (state, getters) => {
      let edacsSectionName = 'chestpain-pathway-points';
      let sectionAnswers = getters.getSection(edacsSectionName);
      let total = 0;
      for (let item in sectionAnswers) {
        let num = sectionAnswers[item];
        total += 1*num;
      }
      return total;
    },
    getTropDelta: (state, getters) => {
      return Math.abs(state.userData[state.userId].answers.trop_calculator.trop_calc_02 - state.userData[state.userId].answers.trop_calculator.trop_calc_01);
    },
    getUsers: (state) => {
      return state.users;
    },
    getUserData: (state) => {
      if (typeof state.userData[state.userId] === 'undefined') Vue.set(state.userData, state.userId, {});
      return state.userData[state.userId];
    },
    getSection: (state, getters) => (section) => {
      let userData = getters.getUserData;
      if (typeof userData.answers === 'undefined') Vue.set(userData, 'answers', {});
      if (typeof userData.answers[section] === 'undefined') Vue.set(userData.answers, section, {});
      let sectionAnswers = userData.answers[section];
      return sectionAnswers;
    },
    getSectionAnswer: (state, getters) => (payload) => {
      let answerId = payload.answerId;
      let sectionAnswers = getters.getSection(payload.section);
      if (typeof sectionAnswers[answerId] === 'undefined') Vue.set(sectionAnswers, answerId, undefined);
      return sectionAnswers[answerId];
    },
    sectionSomeChecked: (state, getters) => (section) => {
      let sectionAnswers = getters.getSection(section);
      let result = sectionAnswers && Object.keys(sectionAnswers).some( el => sectionAnswers[el] === true );
      return result;
    },
    sectionEveryChecked: (state, getters) => (section) => {
      let sectionAnswers = getters.getSection(section);
      let result = sectionAnswers && Object.keys(sectionAnswers).every( el => sectionAnswers[el] === true );
      return result;
    },
  },
  plugins: [vuexPersist.plugin],
});