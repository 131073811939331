<template>
  <f7-page noNavbar name="st-elevation-yes">
    <f7-block>
      <h3>Consider:</h3>
    </f7-block>

    <f7-list media-list class="mt-0 mb-0">
      <html-list-item
        v-for="item in questions"
        :checkbox="!(item.isDivider || item.isSecondary)"
        :divider="item.isDivider"
        :name="item.name"
        :key="item.id"
        :value="item.id"
        :checked="getAnswer(item.name) === item.id"
        @change="setAnswerRadio"
        :title="item.title"
        :subtitle="item.subtitle"
        :text="item.text"
        :class="item.class"
      ></html-list-item>
    </f7-list>

    <f7-block strong :class="{ disabled: getAnswer('peri_radio') !== 'peri7' }">
      <h2>Go immediately to:</h2>
      <f7-button fill href="/stemi-management/">STEMI MANAGEMENT</f7-button>
    </f7-block>
  </f7-page>
</template>

<script>
import HtmlListItem from "./html-list-item.vue";
import helpers from "./helpers.vue";
export default {
  extends: helpers,
  mounted() {
    document.title = 'ST Elevation ECG Criteria - Blacktown Hospital PACSA'
  },
  data() {
    return {
      section: "peri_answers",
      questions: [
        {
          id: "peri1",
          name: "peri_radio",
          title: "Pericarditis",
          subtitle:
            '<p class="para with-btn">(sharp chest pain, respiratory or positional component)</p><p class="para mb-0"><a href="https://litfl.com/pericarditis-ecg-library/" class="button button-fill button-sub external">More information</a></p>'
        },
        {
          id: "peri2",
          name: "peri_radio",
          title: '<p class="para with-btn">Intracranial bleed</p>',
          subtitle:
            '<p class="para mb-0"><a href="https://litfl.com/raised-intracranial-pressure-ecg-library/" class="button button-fill button-sub external">More information</a></p>'
        },
        {
          id: "peri3",
          name: "peri_radio",
          title: '<p class="para with-btn">Ventricular aneurysm</p>',
          subtitle:
            '<p class="para mb-0"><a href="https://litfl.com/sgarbossa-criteria-ecg-library/" class="button button-fill button-sub external">More information</a></p>'
        },
        {
          id: "peri4",
          name: "peri_radio",
          title: '<p class="para with-btn">Early repolarisation</p>',
          subtitle:
            '<p class="para mb-0"><a href="https://litfl.com/benign-early-repolarisation-ecg-library/" class="button button-fill button-sub external">More information</a></p>'
        },
        {
          id: "peri5",
          name: "peri_radio",
          title: '<p class="para with-btn">Brugada syndrome</p>',
          subtitle:
            '<p class="para mb-0"><a href="https://litfl.com/brugada-syndrome-ecg-library/" class="button button-fill button-sub external">More information</a></p>'
        },
        {
          id: "peri6",
          name: "peri_radio",
          title: "Wellens syndrome",
          subtitle:
            '<p class="para with-btn">No precordial Q waves and normal R wave progression, minimally elevated ST segment <1mm and Inverted or biphasic T waves V1-V6 (mainly V2-V3).</p><p class="para mb-0"><a href="https://litfl.com/wellens-syndrome-ecg-library/" class="button button-fill button-sub external">More information</a></p>'
        },
        {
          id: "peri7",
          name: "peri_radio",
          title: "None of the above"
        }
      ]
    };
  },
  components: {
    HtmlListItem
  }
};
</script>
