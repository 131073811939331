<template>
  <f7-page noNavbar name="ecg-and-vital-signs">

    <f7-block class="text-center">
      <h3 class="mb-0">ECG & Vital Signs</h3>
      <div style="margin-bottom:10px;"><em>ECG expert interpretation within 10 minutes</em></div>
      <div><em>Repeat ECG in 2hr if stable repeat every 30 minutes if ongoing symptoms</em></div>
    </f7-block>

    <f7-block class="mb-0 mt-0" strong><strong>GENERAL MANAGEMENT:</strong></f7-block>
    <f7-list media-list class="mb-0 mt-0">
      <f7-list-item
        v-for="item in questions"
        checkbox
        :name="item.name"
        :key="item.id"
        :value="item.id"
        :checked="getAnswer(item.id)===true"
        @change="setAnswerCheckbox"
        :title="item.title"
        :subtitle="item.subtitle"
        :text="item.text"
      ></f7-list-item>
    </f7-list>

    <f7-block class="text-center">
      <h3 class="mb-0">ST ELEVATION</h3>
      <div class="mb-2"><em>or (presumed new) LBBB</em></div>
      <f7-row>
        <f7-col>
          <f7-button fill href="/st-elevation-ecg-criteria/">Yes</f7-button>
        </f7-col>
        <f7-col>
          <f7-button fill href="/st-elevation-no/">No</f7-button>
        </f7-col>
      </f7-row>
    </f7-block>

  </f7-page>
</template>

<script>
  import helpers from './helpers.vue';
  export default {
    extends: helpers,
    mounted() {
      document.title = 'ECG & Vital Signs - Blacktown Hospital'
    },
    data() {
      return {
        section: 'ecg_answers',
        questions: [
          { id: 'ecg1', name: 'ecg_answers', title: 'Oxygen if O2 sat <93%' },
          { id: 'ecg2', name: 'ecg_answers', title: 'Aspirin' },
          { id: 'ecg3', name: 'ecg_answers', title: 'Pain Relief' },
          { id: 'ecg4', name: 'ecg_answers', title: 'IV Access' },
          { id: 'ecg5', name: 'ecg_answers', title: 'Pathology (incl Troponin)' },
          { id: 'ecg6', name: 'ecg_answers', title: 'Chest X-ray' },
          // { id: 'ecg6a', name: 'ecg_answers', title: 'Dependent Chest X-ray', disabledBy: 'ecg6' },
        ],
      }
    }
  }
</script>