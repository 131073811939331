<template>
  <f7-page noNavbar name="st-elevation-ecg-criteria">
    
    <f7-block class="text-center">
      <p class="mb-0 med-page-title"><strong>ST Elevation ECG Criteria</strong></p>
    </f7-block>

    <template v-for="item in questions">
        <f7-list media-list class="mt-0 mb-0" :key="item.key">
            <html-list-item
                :checkbox="!(item.isDivider||item.isSecondary)"
                :divider="item.isDivider"
                :name="item.name"
                :key="item.id"
                :value="item.id"
                
                :checked="getAnswer(item.id)===true"
                @change="setAnswerCheckbox"
                :title="item.useEdacsTitle === true ? edacsTitle : item.title"
                :subtitle="item.subtitle"
                :text="item.text"
                :class="item.class"
            ></html-list-item>
            <html-list-item class="bg-tan"
            v-if="item.action&&getAnswer(item.id)===true"
            :key="item.id+'-action'"
            :title="item.action"
            ></html-list-item>
        </f7-list>
    </template>

    <f7-toolbar bottom>
      <div></div>
      <f7-link href="/st-elevation-yes/">Next step <f7-icon size="20px" ios="f7:chevron_right"></f7-icon></f7-link>
    </f7-toolbar>
    
  </f7-page>
</template>

<script>
  import HtmlListItem from './html-list-item.vue';
  import helpers from './helpers.vue';
  export default {
    extends: helpers,
    mounted() {
      document.title = 'ST Elevation ECG Criteria - Blacktown Hospital PACSA'
    },
    data() {
      return {
        section: 'st-elevation-ecg-criteria',
        nextPageSome: '/management-risk-high/',
        nextPageNone: '/stratify-acs-risk-intermediate/',
        questions: [
          { id: 'ecg-crit-01', name: 'ecg_crit', title: 'ST elevation of ≥1mm in ≥2 adjacent leads (except V2 and V3)', subtitle: '(Including: aspirin, O<sub>2</sub> ,GTN, morphine)' },
          { id: 'ecg-crit-01a', name: 'ecg_crit', title: '<strong>ST elevation in V2 and V3 requires</strong>', isSecondary: true, class: 'bg-grey' },
          { id: 'ecg-crit-02', name: 'ecg_crit', title: '≥2.5mm in men <40 years' },
          { id: 'ecg-crit-03', name: 'ecg_crit', title: '≥2.0mm in men ≥40 years' },
          { id: 'ecg-crit-04', name: 'ecg_crit', title: '≥1.5mm in women' },
          { id: 'ecg-crit-05', name: 'ecg_crit', title: 'Posterior infarct', action: '<p class="para" style="padding-left:36px;margin-bottom:20px;">a. R wave greater than S wave in V1-V2<br>b. ST depression V1-V3 on standard ECG<br>c. ST elevation V7-V9 on posterior ECG</p><p class="para" style="padding-left:36px;padding-right:36px;margin-bottom:20px;"><img width="400" src="static/images/posterior_infarct_ecg.png" style="max-width:100%;display:block;"></p><p class="para mb-0" style="padding-left:36px;padding-right:36px;"><a href="https://litfl.com/posterior-myocardial-infarction-ecg-library/" class="button button-fill external">More information</a></p>' },
          { id: 'ecg-crit-06', name: 'ecg_crit', title: 'De Winters T waves V2-V5', action: '<p class="para" style="padding-left:36px;margin-bottom:20px;">a. Up-sloping ST depression in V2-V5<br>b. Tall T waves in chest leads: V2-V5<br>c. Slight ST elevation aVR > 0.5mm</p><p class="para" style="padding-left:36px;padding-right:36px;margin-bottom:20px;"><img width="400" src="static/images/de_winter_t_waves.png" style="max-width:100%;display:block;"></p><p class="para mb-0" style="padding-left:36px;padding-right:36px;"><a href="https://litfl.com/de-winter-t-wave-ecg-library/" class="button button-fill external">More information</a></p>' },
          { id: 'ecg-crit-07', name: 'ecg_crit', title: 'LBBB criteria' },
          { id: 'ecg-crit-07a', name: 'ecg_crit', title: '<p class="para mb-0" style="padding-left:36px;padding-right:36px;"><a href="/lbbb-criteria/" class="button button-fill">View LBBB criteria</a></p>', isSecondary: true, class: '' },
        ],
      }
    },
    components: {
      HtmlListItem,
    },
  }
</script>