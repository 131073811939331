<template>
  <f7-page noNavbar name="stemi-management">

    <f7-block class="text-center">
      <p class="mb-0 med-page-title"><strong>STEMI Management</strong></p>
    </f7-block>

    <template v-for="block in blocks">
      <f7-list media-list class="mt-0 mb-0" :key="block.key">
        <f7-block strong class="mt-0 mb-0">
          <strong>{{block.title}}</strong>
        </f7-block>
        <template v-for="item in block.questions">

          <html-list-item
            v-if="item.elType!='radio'"
            :checkbox="!(item.isDivider||item.isSecondary)"
            :divider="item.isDivider"
            :name="item.name"
            :key="item.id"
            :value="item.id"
            :checked="getAnswer(item.id)===true"
            @change="setAnswerCheckbox"
            :title="item.title"
            :subtitle="item.subtitle"
            :text="item.text"
            :elType="item.elType"
          ></html-list-item>

          <html-list-item
            v-if="item.elType==='radio'"
            :checkbox="!(item.isDivider||item.isSecondary)"
            :divider="item.isDivider"
            :name="item.name"
            :key="item.id"
            :value="item.id"
            :checked="getAnswer(item.name)===item.id"
            @change="setAnswerRadio"
            :title="item.title"
            :subtitle="item.subtitle"
            :text="item.text"
            :elType="item.elType"
          ></html-list-item>

          <f7-list-item class="text-color-black action-block"
            v-if="item.elType!='radio'&&item.action&&(getAnswer(item.id)===true||getAnswer(item.name)===item.id)"
            :key="item.id+'-action'"
            :title="item.action"
          ></f7-list-item>

          <html-list-item class="bg-white"
          v-if="item.elType==='radio'&&item.action&&(getAnswer(item.id)===true||getAnswer(item.name)===item.id)"
          :key="item.id+'-action'"
          :title="item.action"
          ></html-list-item>

        </template>
      </f7-list>
      <!-- <f7-list-item divider v-if="index < blocks.length-1" :key="'div'+block.key"></f7-list-item> -->
    </template>

  </f7-page>
</template>

<script>
  import HtmlListItem from './html-list-item.vue';
  import helpers from './helpers.vue';
  export default {
    extends: helpers,
    mounted() {
      document.title = 'STEMI Management - Blacktown Hospital PACSA'
    },
    data() {
      return {
        section: 'stemi_management',
        blocks: [
          {
            key: 'confirm',
            title: '1. Confirm indications for primary PCI',
            questions: [
              { id: 'cath-lab-0', name: 'stemi-check', title: 'Access to Cath lab within 60 minutes:', isSecondary: true },
              { id: 'cath-lab-1', name: 'stemi-radio', title: 'Yes', elType: 'radio' },
              { id: 'cath-lab-2', name: 'stemi-radio', title: 'No', elType: 'radio', action: '<p class="para mb-0" style="padding-left:36px;padding-right:36px;"><a href="/thrombolysis/" class="button button-fill">Consider Thrombolysis</a></p>' },
              { id: 'cath-divider', name: 'stemi-check', title: '', isDivider: true },
              { id: 'stemi-confirm-1', name: 'stemi-check', title: 'Chest pain less than 12 hours (if &gt;12hrs but &lt; 24hrs discuss with cardiologist if other criteria fulfilled)' },
              { id: 'stemi-confirm-2', name: 'stemi-check', title: 'ST segment elevation ≥1mm in 2 limb leads or ≥2mm in adjacent chest leads (except V2 and V3) or new LBBB' },
              { id: 'stemi-confirm-2a', name: 'stemi-check', title: '<p class="para mb-0" style="padding-left:36px;padding-right:36px;"><a href="/st-elevation-ecg-criteria/" class="button button-fill">View ST Elevation ECG Criteria</a></p>', isSecondary: true },
              { id: 'stemi-confirm-3', name: 'stemi-check', title: 'Myocardial infarction likely from history' },
              { id: 'stemi-confirm-4', name: 'stemi-check', title: 'Patient does <strong>NOT</strong> have advanced dementia*' },
              { id: 'stemi-confirm-5', name: 'stemi-check', title: 'Patient is <strong>NOT</strong> a nursing home resident requiring full nursing care*' },
              { id: 'stemi-confirm-6', name: 'stemi-check', title: '(*If present consider conservative medical management after discussion with consultant on call.)', isSecondary: true },
            ],
          },
          {
            key: 'notify',
            title: '2. Notify',
            questions: [
              { id: 'stemi-notify-1', name: 'stemi-check', title: '0800-1700 Mon-Fri notify cardiology Advanced Trainee <strong>0427 094 834<strong>', isSecondary: true },
              { id: 'stemi-notify-2', name: 'stemi-check', title: 'Arrange transport to BTH cath lab', isSecondary: true },
              { id: 'stemi-notify-3', name: 'stemi-check', title: '1700-0800 Mon-Fri or Weekends/public holidays notify BTH cardiologist on call via switchboard. If cardiologist agree for PCI then', isSecondary: true },
              { id: 'stemi-notify-4', name: 'stemi-check', title: 'Book ambulance transfer with lights and sirens as soon as decision made for lab transfer to WMH Cath lab bypass WMH ED', isSecondary: true },
              { id: 'stemi-notify-5', name: 'stemi-check', title: 'Notify International cardiologist on call at WMH via WMH switch when the patient is loaded into ambulance for transfer.', isSecondary: true },
            ],
          },
          {
            key: 'general',
            title: '3. General management',
            questions: [
              { id: 'stemi-mgmt-1', name: 'stemi-check', title: 'Cardiac monitoring' },
              { id: 'stemi-mgmt-2', name: 'stemi-check', title: 'Routine bloods' },
              { id: 'stemi-mgmt-3', name: 'stemi-check', title: 'Oxygen' },
              { id: 'stemi-mgmt-4', name: 'stemi-check', title: 'GTN/Morphine' },
              { id: 'stemi-mgmt-5', name: 'stemi-check', title: 'CXR' },
              { id: 'stemi-mgmt-6', name: 'stemi-check', title: 'IV Cannula x2 (if possible; preferably left arm but avoid right hand/wrist)' },
              { id: 'stemi-mgmt-7', name: 'stemi-check', title: 'Beta blocker (oral metoprolol 25-50mg BD, <strong>not IV</strong>) use only <strong>pre</strong> PCI if:', isSecondary: true },
              { id: 'stemi-mgmt-8', name: 'stemi-check', title: 'hypertensive SBP&gt;140 with HR&gt;60; OR' },
              { id: 'stemi-mgmt-9', name: 'stemi-check', title: 'tachycardic HR&gt;85 with SBP &gt;110.' },
            ],
          },
          {
            key: 'anti',
            title: '4. Antithrombotic therapy',
            questions: [
              { id: 'stemi-anti-1', name: 'stemi-check', title: '<strong>Aspirin 300mg</strong>' },
              { id: 'stemi-anti-2', name: 'stemi-check', title: '<strong>Second antiplatelet</strong>;' },
              { id: 'stemi-anti-3', name: 'stemi-check', title: 'If not on oral anticoagulation age &lt;70, Weight &gt;60Kg no previous TIA /stroke Prasugrel 60mg' },
              { id: 'stemi-anti-4', name: 'stemi-check', title: 'If Prasugrel contraindicated not on oral anticoagulation Ticagrelor 180mg unless HR &lt;50bpm or 2nd or 3rd degree heart block' },
              { id: 'stemi-anti-5', name: 'stemi-check', title: 'If Ticagrelor contraindicated Clopidogrel 600mg' },
              { id: 'stemi-anti-6', name: 'stemi-check', title: '<strong>Heparin 60mg/kg IV bolus up to 5000&nbsp;units</strong> (withhold if on oral anticoagulation)' },
            ],
          },
          {
            key: 'prep',
            title: '5. Preparation for primary PCI',
            hasActionBlock: true,
            questions: [
              { id: 'stemi-prep-1', name: 'stemi-check', title: 'Allergic to iodine/ contrast', action: 'IV Hydrocortisone 200mg IV stat' },
              { id: 'stemi-prep-2', name: 'stemi-check', title: 'Poor femoral pedal pulses', action: 'Inform cardiologist' },
              { id: 'stemi-prep-3', name: 'stemi-check', title: 'Abnormal renal function', action: 'Inform cardiologist' },
              { id: 'stemi-prep-4', name: 'stemi-check', title: 'Prior CABG', action: 'Inform cardiologist and try to obtain report' },
              { id: 'stemi-prep-5', name: 'stemi-check', title: 'Increased risk of bleeding', action: 'Inform cardiologist' },
              { id: 'stemi-prep-6', name: 'stemi-check', title: 'Unable to gain consent', action: 'Inform cardiologist' },
            ],
          },
          {
            key: 'fast',
            title: 'Fast track',
            questions: [
              { id: 'stemi-fast-1', name: 'stemi-check', title: 'Ensure no delays, every 10min delay to lab increases absolute mortality rate by 0.2%', isSecondary: true },
            ],
          },
        ],
      }
    },
    components: {
      HtmlListItem,
    },
  }
</script>