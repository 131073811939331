<template>
  <f7-page noNavbar name="stratify-acs-risk-high">
    
    <f7-block strong class="high-risk-block mb-0 mt-0">
      <h3 class="mb-1 color-white">High Risk</h3>
      <div><em>If you select <strong>ANY</strong> of the following you will be taken straight to <strong>HIGH RISK</strong> recommended further management</em></div>
    </f7-block>

    <f7-list media-list class="mt-0 mb-0">
      <html-list-item
        v-for="item in questions_1"
        :checkbox="!(item.isDivider||item.isSecondary)"
        :name="item.name"
        :key="item.id"
        :value="item.id"
        :checked="getAnswer(item.id)===true"
        @change="setAnswerCheckboxUncheckNone"
        :title="item.title"
        :subtitle="item.subtitle"
      ></html-list-item>
    </f7-list>

    <f7-block strong class="mb-0 mt-0">
      <div class="block-text"><strong>ECG - NEW</strong> changes in <strong>2</strong> contigious leads of:</div>
    </f7-block>

    <f7-list media-list class="mt-0 mb-0">
      <html-list-item
        v-for="item in questions_2"
        :checkbox="!(item.isDivider||item.isSecondary)"
        :name="item.name"
        :key="item.id"
        :value="item.id"
        :checked="getAnswer(item.id)===true"
        @change="setAnswerCheckboxUncheckNone"
        :title="item.title"
        :subtitle="item.subtitle"
      ></html-list-item>
    </f7-list>

    <f7-block strong class="mb-0 mt-0">
      
    </f7-block>

    <f7-list media-list class="mt-0 mb-0">
      <html-list-item
        key="acshNone"
        id="acshNone"
        value="acshNone"
        name="acs_risk"
        checkbox
        :checked="getAnswer('acshNone')===true"
        @change="checkNone"
        title="None of the above"
      ></html-list-item>
    </f7-list>

    <f7-toolbar bottom>
      <div></div>
      <f7-link :class="{disabled: !sectionSomeChecked()}" :href="someAnswers(['acshNone'])?nextPageNone:nextPageSome">Next<f7-icon size="20px" ios="f7:chevron_right"></f7-icon></f7-link>
    </f7-toolbar>
    
  </f7-page>
</template>

<script>
  import HtmlListItem from './html-list-item.vue';
  import helpers from './helpers.vue';
  export default {
    extends: helpers,
    mounted() {
      document.title = 'High Risk - Stratify - Blacktown Hospital PACSA'
    },
    data() {
      return {
        section: 'acs_risk_high',
        nextPageSome: '/management-risk-high/',
        nextPageNone: '/stratify-acs-risk-low/',
        questions_1: [
          { id: 'acsh1', name: 'acs_risk', title: 'Ongoing <strong>typical</strong> chest pain despite initial treatment in ED', subtitle: '(Including: aspirin, O<sub>2</sub> ,GTN, morphine)' },
          { id: 'acsh2', name: 'acs_risk', title: 'Ongoing chest pain and SBP &lt; 90' },
          { id: 'acsh3', name: 'acs_risk', title: 'Chest pain and acute pulmonary oedema' },
          { id: 'acsh4', name: 'acs_risk', title: 'Chest pain and new mitral regurgitation' },
          { id: 'acsh5', name: 'acs_risk', title: 'Chest pain and new arrhythmia' },
        ],
        questions_2: [
          { id: 'acsh6', name: 'acs_risk', title: 'ST elevation &gt; 0.5mm', subtitle: '(but not fulfilling STEMI criteria)' },
          { id: 'acsh7', name: 'acs_risk', title: 'ST depression &gt; 0.5mm' },
          { id: 'acsh8', name: 'acs_risk', title: 'Deep T wave inversion &gt; 3mm' },
          { id: 'acsh9', name: 'acs_risk', title: '<strong>HS troponin I</strong> > 50ng/L', subtitle: '<p class="para">(other causes excluded)</p><p class="para mb-0"><a href="/alternative-causes-of-troponin-elevation/" class="button button-fill" style="font-style: normal;display: inline-block;">Alternative causes of troponin elevation</a></p>' },
        ],
      }
    },
    components: {
      HtmlListItem,
    },
    methods: {
      setAnswerCheckboxUncheckNone: function(event) {
        this.uncheckById("acshNone");
        this.setAnswerCheckbox(event);
      },
      checkNone: function(event) {
        this.setAnswerCheckbox(event);
        if (event.target.checked === true) {
          for (var item of this.questions_1) this.uncheckById(item.id);
          for (var item of this.questions_2) this.uncheckById(item.id);
        }
      }
    },
  }
</script>