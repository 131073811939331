<template>
  <f7-page noNavbar name="stratify-acs-risk-intermediate">

    <f7-block strong class="intermediate-risk-block mb-0 mt-0">
      <h3 class="mb-1 color-black">Intermediate Risk</h3>
      <div>Recommended Further Management</div>
    </f7-block>

    <f7-list media-list class="mb-0 mt-0">
      <html-list-item
        v-for="item in questions1"
        :checkbox="!(item.isDivider||item.isSecondary)"
        :divider="item.isDivider"
        :name="item.name"
        :key="item.id"
        :value="item.id"
        :checked="getAnswer(item.id)===true"
        @change="setAnswerCheckbox"
        :title="item.useEdacsTitle === true ? edacsTitle : item.title"
        :subtitle="item.subtitle"
        :text="item.text"
        :class="item.class"
      ></html-list-item>
    </f7-list>

    <f7-list media-list class="mb-0 mt-0">
      <template v-for="item in questions2">
        <html-list-item
          :checkbox="!(item.isDivider||item.isSecondary)"
          :divider="item.isDivider"
          :name="item.name"
          :key="item.id"
          :value="item.id"
          :checked="getAnswer(item.name)===item.id"
          @change="setAnswerRadio"
          :title="item.title"
          :subtitle="item.subtitle"
          :text="item.text"
          :elType="item.elType"
        ></html-list-item>

        <html-list-item class="bg-white"
        v-if="item.action&&(getAnswer(item.id)===true||getAnswer(item.name)===item.id)"
        :key="item.id+'-action'"
        :title="item.action"
        ></html-list-item>
      </template>
    </f7-list>

    <div :class="{hidden: getAnswer('mgt_risk_int_trop')!=='mgti04b'}">
      <f7-list media-list class="mb-0 mt-0">
        <html-list-item
          v-for="item in questions3"
          :checkbox="!(item.isDivider||item.isSecondary)"
          :divider="item.isDivider"
          :name="item.name"
          :key="item.id"
          :value="item.id"
          :checked="getAnswer(item.id)===true"
          @change="setAnswerCheckbox"
          :title="item.useEdacsTitle === true ? edacsTitle : item.title"
          :subtitle="item.subtitle"
          :text="item.text"
          :class="item.class"
        ></html-list-item>
      </f7-list>
    </div>
    
  </f7-page>
</template>

<script>
  import HtmlListItem from './html-list-item.vue';
  import helpers from './helpers.vue';
  export default {
    extends: helpers,
    mounted() {
      document.title = 'Intermediate Risk - Management - Blacktown Hospital PACSA'
    },
    data() {
      return {
        section: 'management-risk-intermediate',
        questions1: [
          { id: 'mgti01', name: 'mgt_risk', title: '1. <strong>Aspirin</strong> 300mg stat 100mg daily' },
          { id: 'mgti02', name: 'mgt_risk', title: '2. <strong>Pain relief</strong> with GTN, morphine as required, and if appropriate NSAID, Proton Pump inhibitor.' },
          { id: 'mgti03', name: 'mgt_risk', title: '3. <strong>Monitored</strong> bed' },
          { id: 'mgti04', name: 'mgt_risk', title: '4. Repeat <strong>troponin</strong> and ECG at <strong>2 hours</strong>' },
          { id: 'mgti04divider', name: 'mgt_risk', isDivider: true },
          // { id: 'mgti04a', name: 'mgt_risk', title: 'HS trop at 2 hrs 2h is >50 or Delta is >15 ng/L' },
          // { id: 'mgti04b', name: 'mgt_risk', title: 'HS trop ≤ 50ng/L <strong>and</strong> 2h Delta is ≤15 ng/L' },
        ],
        questions2: [
          { id: 'mgti04a', name: 'mgt_risk_int_trop', title: 'HS trop at 2 hrs 2h is >50 or Delta is >15 ng/L', elType: 'radio', action: '<p class="para mb-0" style="padding-left:36px;padding-right:36px;"><a href="/management-risk-high/" class="button button-fill">Continue to High Risk</a></p>' },
          { id: 'mgti04b', name: 'mgt_risk_int_trop', title: 'HS trop ≤ 50ng/L <strong>and</strong> 2h Delta is ≤15 ng/L', elType: 'radio' },
        ],
        questions3: [
          // { id: 'mgtid01', name: 'mgt_risk', isDivider: true },
          { id: 'mgti05', name: 'mgt_risk', title: '<div class="block-text"><strong>IF still intermediate risk after 2hr Trop</strong></div>', isSecondary: true, class: 'bg-grey' },
          { id: 'mgti06', name: 'mgt_risk', title: 'Exclude alternative causes for elevated troponin' },
          //{ id: 'mgti07', name: 'mgt_risk', title: 'Complete Emergency Department Assessment of Chest Pain Score EDACS' },
          { id: 'mgti07', name: 'mgt_risk', title: '<p class="para">Complete Emergency Department Assessment of Chest Pain Score</p><p class="para mb-0"><a href="/edacs/" class="button button-fill">EDACS</a></p>', isSecondary: true },
          { id: 'mgti07a', name: 'mgt_risk', title: '', isSecondary: true, class: 'edacs', useEdacsTitle: true },
          // { id: 'mgtid02', name: 'mgt_risk', isDivider: true },
          { id: 'mgti08', name: 'mgt_risk', title: '<div class="block-text"><strong>Assess number of the following intermediate risk criteria and need for inpatient testing or early discharge</strong></div>', isSecondary: true, class: 'bg-grey' },
          // { id: 'mgti09', name: 'mgt_risk', title: '1. Either sample HS Trop &gt;17 ng/L with ≤50% change' },
          { id: 'mgti10', name: 'mgt_risk', title: '1. ECG <strong>new</strong> moderate T wave inversion &gt;1mm  &lt;3mm in 2 contiguous leads (exclude aVR ,V1)' },
          { id: 'mgti11', name: 'mgt_risk', title: '2. <strong>Typical</strong> chest pain occurred at rest/prolonged >10min/repetitive' },
          { id: 'mgti12', name: 'mgt_risk', title: '3. Emergency Department Assessment of Chest Pain Score <strong>EDACS</strong> &gt;20' },
          // { id: 'mgtid03', name: 'mgt_risk', isDivider: true },
          { id: 'mgti13', name: 'mgt_risk', title: '<div class="block-text"><strong>If &gt;1 criteria then inpatient investigation contact cardiology for input then;</strong></div>', isSecondary: true, class: 'bg-grey' },
          { id: 'mgti13a', name: 'mgt_risk', title: 'EST' },
          { id: 'mgti13b', name: 'mgt_risk', title: 'CTCA' },
          { id: 'mgti13c', name: 'mgt_risk', title: 'Other' },
          // { id: 'mgtid04', name: 'mgt_risk', isDivider: true },
          { id: 'mgti14', name: 'mgt_risk', title: '<div class="block-text"><strong>If only one criteria may discharge home (with all of):</strong></div>', isSecondary: true, class: 'bg-grey' },
          { id: 'mgti15', name: 'mgt_risk', title: 'Alternative diagnosis excluded' },
          { id: 'mgti16', name: 'mgt_risk', title: 'Aspirin 100mg daily' },
          { id: 'mgti17', name: 'mgt_risk', title: 'Appropriate outpatient testing within 1wk.' },
          { id: 'mgti17a', name: 'mgt_risk', title: 'EST' },
          { id: 'mgti17b', name: 'mgt_risk', title: 'CTCA' },
          { id: 'mgti17c', name: 'mgt_risk', title: 'Other' },
          { id: 'mgti17d', name: 'mgt_risk', title: '<p class="para mb-0" style="padding-left:36px;"><a href="/testing-guideline/" class="button button-fill">NON INVASIVE TESTING GUIDELINE</a></p>', isSecondary: true },
          { id: 'mgti18', name: 'mgt_risk', title: 'Give discharge instructions' },
          // { id: 'mgtid05', name: 'mgt_risk', isDivider: true },
          { id: 'mgti19', name: 'mgt_risk', title: 'On call cardiologist may be contacted to discuss discharge plan if required. This is not mandatory if fulfils above guideline. If doubt remains contact on call cardiologist.', isSecondary: true },
          // { id: 'mgti20', name: 'mgt_risk', title: '<strong>† Re-stratisfy after 2nd HS Troponin I at 3hrs after initial sample on arrival in ED</strong>', isSecondary: true, class:"bg-blue" },
          // { id: 'mgti21', name: 'mgt_risk', title: 'Either sample &gt;17ng/L with &le;50% change (increase or decrease)' },
          { id: 'mgti22', name: 'mgt_risk', title: '- Consider alternative cause of elevation', isSecondary: true },
        ],
      }
    },
    computed: {
      edacsTitle() {
        return `Current EDACS Score: <strong>${this.$store.getters.getEdacsScore}</strong>`;
      },
    },
    components: {
      HtmlListItem,
    },
  }
</script>