<template>
  <li v-if="elType == 'checkbox'" class="media-item">
    <label class="item-content item-checkbox">
      <input :name="name" type="checkbox" :value="value" :checked="checked" @change="onChange" @click="onClick" :points="points"><i class="icon icon-checkbox"></i>
      <div class="item-inner">
        <div class="item-title-row">
          <div class="item-title" v-html="title"></div>
        </div>
        <div class="item-subtitle" v-html="subtitle"></div>
      </div>
    </label>
  </li>
  <li v-else-if="elType == 'radio'" class="media-item">
    <label class="item-content item-radio">
      <input :name="name" type="radio" :value="value" :checked="checked" @click="onClick" :points="points"><i class="icon icon-radio"></i>
      <div class="item-inner">
        <div class="item-title-row">
          <div class="item-title" v-html="title"></div>
        </div>
        <div class="item-subtitle" v-html="subtitle"></div>
      </div>
    </label>
  </li>
  <li v-else-if="elType == 'divider'" class="item-divider media-item">
    <span class="" v-html="title"></span>
  </li>
  <li v-else class="media-item">
    <div class="item-content">
      <div class="item-inner">
        <div class="item-title-row">
          <div class="item-title" v-html="title"></div>
        </div>
        <div class="item-subtitle" v-html="subtitle"></div>
      </div>
    </div>
  </li>
</template>

<script>
  export default {
    name: 'html-list-item',
    props: 
      [
        'name',
        'title',
        'subtitle',
        'checkbox',
        'radio',
        'checked',
        'divider',
        'value',
        'points',
      ],
    methods: {
      onChange(event) {
        this.$emit('change', event);
      },
      onClick(event) {
        this.$emit('click', event);
      },
    },
    computed: {
      elType() {
        let types = ['checkbox','radio','divider'];
        let result = types.find( el => { 
          return (typeof this[el] !== 'undefined' && ( this[el] === true || this[el] ==='' ) )
        });
        return result;
      }
    }
  }
</script>