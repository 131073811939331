<template>
  <f7-page noNavbar name="troponin">

    <f7-block class="text-center">
      <p class="mb-0 med-page-title"><strong>High sensitivity Troponin I</strong></p>
    </f7-block>

    <f7-list media-list class="mt-0 mb-0">
      <html-list-item
        v-for="item in questions"
        :checkbox="!(item.isDivider||item.isSecondary)"
        :divider="item.isDivider"
        :name="item.name"
        :key="item.id"
        :value="item.id"
        
        :checked="getAnswer(item.id)===true"
        @change="setAnswerCheckbox"
        :title="item.useEdacsTitle === true ? edacsTitle : item.title"
        :subtitle="item.subtitle"
        :text="item.text"
        :class="item.class"
      ></html-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
  import HtmlListItem from './html-list-item.vue';
  import helpers from './helpers.vue';
  export default {
    extends: helpers,
    mounted() {
      document.title = 'High sensitivity Troponin I - Blacktown Hospital PACSA'
    },
    data() {
      return {
        section: 'troponin',
        questions: [
          
          { id: 'troponin-01', name: 'nitg_check', title: '<p class="para mb-0">Perform at 0 & 2 hours</p>', isSecondary: true },
          { id: 'troponin-02', name: 'nitg_check', title: '<p class="para mb-0">An initial elevated troponin result does not necessarily indicate AMI, however it does indicate that the patient is not low risk.</p>', isSecondary: true },
          { id: 'troponin-03', name: 'nitg_check', title: '<p class="para mb-0">This is >50ng/L at BMDH using Siemens Vista HS Troponin I assay.</p>', isSecondary: true },
          { id: 'troponin-04', name: 'nitg_check', title: '<p class="para mb-0">This group has higher all-cause mortality as well as greater risk of ACS.</p>', isSecondary: true },
          { id: 'troponin-05', name: 'nitg_check', title: '<p class="para mb-0">A repeat troponin is required at 2 hours after initial sample if a patient has had symptoms within the previous six hours or if the patient is high or intermediate risk.</p>', isSecondary: true },
          { id: 'troponin-06', name: 'nitg_check', title: '<p class="para mb-0">For low risk patients, a single troponin may be sufficient if performed more than six hours after cessation of symptoms.</p>', isSecondary: true },
          { id: 'troponin-07', name: 'nitg_check', title: '<p class="para mb-0">Delta troponin  is now used to indicate a positive result at 2 hours, a change (delta) either up or down of ≥15 ng/L between repeated troponin tests. This is known as a positive delta troponin and is associated with ischaemic causes of chest pain and high risk.</p>', isSecondary: true },
          { id: 'troponin-08', name: 'nitg_check', title: '<p class="para mb-0">Positive Delta is >15 ng/L at BMDH using Siemens Vista HS assay.</p>', isSecondary: true },
          { id: 'troponin-09', name: 'nitg_check', title: '<p class="para mb-0">A troponin is positive by delta even if both troponin results are in the normal range i.e. ≤50 ng/L.</p>', isSecondary: true },
          { id: 'troponin-10', name: 'nitg_check', title: '<p class="para mb-0">High risk either sample >50ng/L or 2h Delta is >15 ng/L</p>', isSecondary: true },
          { id: 'troponin-11', name: 'nitg_check', title: '<p class="para mb-0">Low risk both samples ≤ 50ng/L and 2h Delta is ≤15 ng/L</p>', isSecondary: true },
        ],
      }
    },
    computed: {
      edacsTitle() {
        return `Current <a href="/edacs/">EDACS</a> Score: <strong>${this.$store.getters.getEdacsScore}</strong>`;
      },
    },
    components: {
      HtmlListItem,
    },
  }
</script>