<template>
  <f7-page noNavbar name="chestpain-pathway">
    <f7-block class="text-center">
      <p class="mb-0 med-page-title"><strong>Emergency Department Assessment of Chest Pain Score (EDACS) to assess suitability for early discharge</strong></p>
    </f7-block>

    <f7-list media-list class="mt-0 mb-0">
      <html-list-item
        v-for="item in questions"
        :checkbox="!(item.isDivider||item.isSecondary)"
        :divider="item.isDivider"
        :name="item.name"
        :key="item.id"
        :value="item.id"
        :checked="getAnswer(item.name)===item.id"
        @click="setAnswerRadio"
        :title="item.title"
        :subtitle="item.subtitle"
        :text="item.text"
        :points="item.points"
      ></html-list-item>
    </f7-list>
    <f7-block strong class="mt-0 mb-0 edacs-title">
      <p>EDACS Total: <strong>{{getEdacsScore}}</strong></p>
    </f7-block>
    <table class="data-table edacs-table">
      <thead>
        <tr>
          <th>EDACS Score</th>
          <th>Probability of cardiac event within 30 days</th>
        </tr>
      </thead>
      <tbody>
        <tr :class="{'bg-color-green text-color-white': getEdacsScore<16}">
          <td>&lt;16</td>
          <td>Low probability</td>
        </tr>
        <tr :class="{'bg-color-deeporange text-color-white': (getEdacsScore>=16&&getEdacsScore<=20)}">
          <td>16-20</td>
          <td>Intermediate probability</td>
        </tr>
        <tr :class="{'bg-color-red text-color-white': getEdacsScore>20}">
          <td>&gt;20</td>
          <td>High probability</td>
        </tr>
      </tbody>
    </table>
    <f7-block>
      <p><span class="nbsymbol">i) </span>Known coronary artery disease = previous AMI, CABG or PCI/Stent</p>
      <p><span class="nbsymbol">ii) </span>Risk factors = family history of premature CAD &lt; 60yrs in 1&deg; relative, dyslipidaemia, diabetes, hypertension, current smoker.</p>
      <p><span class="nbsymbol">† </span>Pain that caused presentation to hospital</p>
    </f7-block>
  </f7-page>
</template>

<script>
  import HtmlListItem from './html-list-item.vue';
  import helpers from './helpers.vue';
  export default {
    extends: helpers,
    mounted() {
      document.title = 'EDACS - Blacktown Hospital PACSA'
    },
    data() {
      return {
        section: 'chestpain-pathway',
        questions: [
          { id: 'cppath-01', name: 'ste_radio_age', points: '+0', title: '<strong>a) Age</strong><br>(select single best answer)', isSecondary: true },
          { id: 'cppath-02', name: 'ste_radio_age', points: '+2', title: '18–45' },
          { id: 'cppath-03', name: 'ste_radio_age', points: '+4', title: '46–50' },
          { id: 'cppath-04', name: 'ste_radio_age', points: '+6', title: '51–55' },
          { id: 'cppath-05', name: 'ste_radio_age', points: '+8', title: '56–60' },
          { id: 'cppath-06', name: 'ste_radio_age', points: '+10', title: '61–65' },
          { id: 'cppath-07', name: 'ste_radio_age', points: '+12', title: '66–70' },
          { id: 'cppath-08', name: 'ste_radio_age', points: '+14', title: '71–75' },
          { id: 'cppath-09', name: 'ste_radio_age', points: '+16', title: '76–80' },
          { id: 'cppath-10', name: 'ste_radio_age', points: '+18', title: '81–85' },
          { id: 'cppath-11', name: 'ste_radio_age', points: '+20', title: '86+' },
          { id: 'cppath-99', name: 'ste_radio_male', points: '0', title: '<strong>b) gender</strong>', isSecondary: true },
          { id: 'cppath-12', name: 'ste_radio_male', points: '+6', title: 'Male' },
          { id: 'cppath-98', name: 'ste_radio_aged', points: '0', title: '<strong>c) Aged 18-50</strong> years <strong>and</strong> either:', isSecondary: true },
          { id: 'cppath-13', name: 'ste_radio_aged', points: '+4', title: '(i) known coronary artery disease' },
          { id: 'cppath-14', name: 'ste_radio_aged', points: '+4', title: '(ii) ≥ 3 risk factors' },
          { id: 'cppath-15', name: 'ste_radio_signs', points: '+6', title: '<strong>d) Symptoms and signs</strong><br>(select each if present)', isSecondary: true },
          { id: 'cppath-16', name: 'ste_radio_signs1', points: '+3', title: 'Diaphoresis' },
          { id: 'cppath-17', name: 'ste_radio_signs2', points: '+5', title: 'Radiates to arm or shoulder' },
          { id: 'cppath-18', name: 'ste_radio_signs3', points: '-4', title: 'Pain† occurred or worsened with inspiration' },
          { id: 'cppath-19', name: 'ste_radio_signs4', points: '-6', title: 'Pain† is reproduced by palpation' },
        ],
      }
    },
    components: {
      HtmlListItem,
    },
  }
</script>